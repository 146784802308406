import * as React from 'react'

import { Content } from '../../../../util/hooks/api/useContentReport'
import { ContentPreviewCell } from '../../cells/ContentPreviewCell'
import { ContentNameCell } from '../../cells/ContentNameCell'
import { ContentCapturedAtCell } from '../../cells/ContentCapturedAtCell'
import { ViewCountCell } from '../../cells/ViewCountCell'
import { ViewRateCell } from '../../cells/ViewRateCell'
import { ViewSecondCell } from '../../cells/ViewSecondCell'
import { GoalCountCell } from '../../cells/GoalCountCell'
import { GoalRateCell } from '../../cells/GoalRateCell'
import { ClickCountCell } from '../../cells/ClickCountCell'
import { ClickRateCell } from '../../cells/ClickRateCell'
import { ContentRow } from '../../rows/ContentRow'
import { CalenderState } from '../../../common/DayPickerRange'

interface Props {
  readonly item: Content
  readonly calenderState: CalenderState
  readonly canViewPartialContent?: boolean
  readonly onClick: React.MouseEventHandler<HTMLDivElement>
  readonly onCheckboxClick: React.MouseEventHandler<HTMLDivElement>
  readonly onEdit: (id: number) => void
  readonly checked?: boolean
  readonly selected?: boolean
  readonly openCaptureHistoryModal: (contentId: number) => void
}

export function AllContentsGridItem({
  item,
  calenderState,
  canViewPartialContent,
  onClick,
  onCheckboxClick,
  onEdit,
  checked,
  selected = false,
  openCaptureHistoryModal,
}: Props) {
  return (
    <ContentRow dataId={item.id} selected={selected} onClick={onClick} tags={item.tags}>
      <ContentPreviewCell
        item={item}
        canViewPartialContent={canViewPartialContent}
        onCheckboxClick={onCheckboxClick}
        onEdit={onEdit}
        checked={checked}
      />
      <ContentNameCell item={item} />
      <ContentCapturedAtCell
        item={item}
        openCaptureHistoryModal={openCaptureHistoryModal}
        calenderState={calenderState}
      />
      <ViewCountCell dataId={item.id} value={item.viewCount} barPercent={item.viewCountScaledPercent} />
      <ViewRateCell dataId={item.id} value={item.viewRate} barPercent={item.viewRateScaledPercent} />
      <ViewSecondCell dataId={item.id} value={item.viewSecond} barPercent={item.viewSecondScaledPercent} />
      <GoalCountCell dataId={item.id} value={item.goalCount} barPercent={item.goalCountScaledPercent} />
      <GoalRateCell
        dataId={item.id}
        goalRate={item.goalRate}
        goalRateRank={item.goalRateRank}
        barPercent={item.goalRateScaledPercent}
      />
      <ClickCountCell dataId={item.id} value={item.clickCount} barPercent={item.clickCountScaledPercent} />
      <ClickRateCell dataId={item.id} value={item.clickRate} barPercent={item.clickRateScaledPercent} />
    </ContentRow>
  )
}
