import { useCookies } from 'react-cookie'

interface Props<T> {
  cookieKey: string
  initialValue: T
}

/**
 * cookieの値取得・値セットを行うHooks
 *
 * @param {object} props - The props object containing the necessary parameters.
 * @param {string} props.cookieKey - The key of the cookie.
 * @param {T} props.initialValue - The initial value of the cookie.
 * @returns {[T, (newValue: T) => void]} - The value of the cookie and the function to set the value of the cookie.
 *
 * @see src/util/hooks/cookie/constants.ts - For the constants cookieKey and initialValue.
 */
// NOTE: 返り値が配列のため利用先で型補完が効かずエラーになるため、返り値の型を明示的に指定した
export const useCookieState = <T>({ cookieKey, initialValue }: Props<T>): [T, (newValue: T) => void] => {
  const [cookies, setCookie] = useCookies([cookieKey])

  const value: T = (() => {
    if (cookies[cookieKey] === undefined) return initialValue
    // cookie値がbooleanやnumberなどのJSON形式ならパースし、そうでないstringなどの場合はそのまま使用する
    return isValidJson(cookies[cookieKey]) ? JSON.parse(cookies[cookieKey]) : cookies[cookieKey]
  })()

  const setValue = (newValue: T) => {
    setCookie(cookieKey, JSON.stringify(newValue), { path: '/' })
  }

  return [value, setValue]
}

/**
 * 文字列がJSON形式として有効かどうかを判定する
 * @param value 検証する文字列
 * @returns JSON形式として有効な場合はtrue、そうでない場合はfalse
 */
function isValidJson(value: string) {
  try {
    JSON.parse(value)
  } catch (e) {
    return false
  }
  return true
}
