import * as React from 'react'

import { ContentGroup } from '../../../../util/hooks/api/useContentGroupReport'
import { ViewCountCell } from '../../cells/ViewCountCell'
import { ViewRateCell } from '../../cells/ViewRateCell'
import { ViewSecondCell } from '../../cells/ViewSecondCell'
import { GoalCountCell } from '../../cells/GoalCountCell'
import { GoalRateCell } from '../../cells/GoalRateCell'
import { ClickCountCell } from '../../cells/ClickCountCell'
import { ClickRateCell } from '../../cells/ClickRateCell'
import { ContentNameLongCell } from '../../cells/ContentNameLongCell'
import { ContentRow } from '../../rows/ContentRow'

interface Props {
  readonly item: ContentGroup
  readonly onClick: React.MouseEventHandler<HTMLDivElement>
  readonly selected?: boolean
}

export function ContentGroupGridItem({ item, onClick, selected = false }: Props) {
  return (
    <ContentRow dataId={item.id} selected={selected} onClick={onClick}>
      <ContentNameLongCell dataId={item.id} item={item} />
      <ViewCountCell dataId={item.id} value={item.viewCount} barPercent={item.viewCountScaledPercent} />
      <ViewRateCell dataId={item.id} value={item.viewRate} barPercent={item.viewRateScaledPercent} />
      <ViewSecondCell dataId={item.id} value={item.viewSecond} barPercent={item.viewSecondScaledPercent} />
      <GoalCountCell dataId={item.id} value={item.goalCount} barPercent={item.goalCountScaledPercent} />
      <GoalRateCell
        dataId={item.id}
        goalRate={item.goalRate}
        goalRateRank={item.goalRateRank}
        barPercent={item.goalRateScaledPercent}
      />
      <ClickCountCell dataId={item.id} value={item.clickCount} barPercent={item.clickCountScaledPercent} />
      <ClickRateCell dataId={item.id} value={item.clickRate} barPercent={item.clickRateScaledPercent} />
    </ContentRow>
  )
}
