import * as React from 'react'
import styled, { css } from 'styled-components'
import cc from 'classcat'
import { GlobalContext } from '../../GlobalState'
import { colors, layout } from '../../styleConstants'
import { navigate, useLocation } from '@gatsbyjs/reach-router'
import { Authority } from '../../util/Authority'
import { isContentReportPage } from '../../util/locations'
import { AccountMenu } from './AccountMenu'
import { usePageState, createProjectStep } from './state/NavigationState'
import { Link as RouterLink } from '@gatsbyjs/reach-router'
import { Logo } from '../common/Logo'
import { ProjectListModal } from '../modal/ProjectListModal'
import { ProjectModal } from '../modal/ProjectModal'
import { ConfirmModal } from '../common/ConfirmModal'
import { Link } from '../common/Link'
import { Settings } from '@styled-icons/ionicons-sharp/Settings'
import { User } from '@styled-icons/boxicons-regular'
import { ChevronsLeft } from '@styled-icons/boxicons-solid/ChevronsLeft'
import { ChevronsRight } from '@styled-icons/boxicons-solid/ChevronsRight'
import { HelpCircle } from '@styled-icons/boxicons-regular/HelpCircle'
import { File } from '@styled-icons/boxicons-regular/File'
import { NotepadEdit } from '@styled-icons/fluentui-system-regular/NotepadEdit'
import { NotificationsNone } from '@styled-icons/material/NotificationsNone'
import { Camera } from '@styled-icons/bootstrap/Camera'
import { CaretLeft } from '@styled-icons/boxicons-regular/CaretLeft'
import { FileImport } from '@styled-icons/boxicons-solid/FileImport'
import smallIcon from '../../../images/logo-mark.svg'
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle'
import { LayoutTextWindowReverse } from '@styled-icons/bootstrap/LayoutTextWindowReverse'
import { Users } from '@styled-icons/heroicons-outline/Users'
import { List } from '@styled-icons/fa-solid/List'
import { LaptopDismiss } from '@styled-icons/fluentui-system-filled/LaptopDismiss'
import { FileExport } from '@styled-icons/boxicons-solid/FileExport'
import { useSelector, useDispatch } from '../../redux/Store'
import { useNavigation } from '../../util/hooks/useNavigation'
import { navigationState, accordionState, onNaviAccordionChange } from '../../redux/navigationSlice'
import { Dot } from '@styled-icons/bootstrap/Dot'
import { Repost } from '@styled-icons/boxicons-regular/Repost'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { BLOG_URL_TOP, HELP_LINKS, SUPPORT_FORM_LINK } from '../../constants'
import { ReportIcon } from '../icons/ReportIcon'
import { BlogIcon } from '../icons/BlogIcon'

// 開閉するメニューの種類
// グローバルでも使用
export const CategoryType = {
  capture: 0,
} as const
interface Props {
  readonly baseUrl?: string
  readonly adminLayout?: boolean
  readonly accountLayout?: boolean
  readonly isReportPage?: boolean // ページレポートまたはコンテンツレポートページか
}

export function Navigation(props: Props) {
  const { baseUrl, adminLayout, accountLayout, isReportPage } = props
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const { opened, clicked, hovered, isIconClickedOnce } = useSelector(navigationState)
  const { reportOpen, captureOpen, projectSettingOpen } = useSelector(accordionState)
  const dispatch = useDispatch()
  const { clickNavigation, hoverNavigation, closeNavigation } = useNavigation()
  const { pathname } = useLocation()
  const openOrCloseClass = opened ? 'open' : 'close'

  const {
    state: {
      checking,
      userId,
      projectId,
      permissions,
      modalOpened,
      newModalOpened,
      accountMenuOpened,
      adminModal,
      createStep,
      errorMessage,
    },
    actions,
  } = usePageState()

  const locationSearch = isReportPage ? window.location.search : ''

  const reportMenu = React.useMemo(() => {
    return [
      {
        name: 'ページ',
        url: `${baseUrl}report/page${locationSearch}`,
        matchCurrentNaviTargetUrls: [`${baseUrl}report/content/`, `${baseUrl}report/page`], // report/pageの後に「/」が付く・付かない両方の考慮が必要だったので前方一致のこちらでも指定必要だった
        dataId: 'report',
      },
      {
        name: '流入',
        url: `${baseUrl}report/traffic${locationSearch}`,
        matchCurrentNaviTargetUrls: [`${baseUrl}report/traffic`],
        dataId: 'traffic-report',
      },
      {
        name: 'カスタムディメンション',
        url: `${baseUrl}report/custom-dimension${locationSearch}`,
        matchCurrentNaviTargetUrls: [`${baseUrl}report/custom-dimension`],
        dataId: 'custom-dimension',
      },
      {
        name: 'ファネル',
        url: `${baseUrl}report/funnel${locationSearch}`,
        matchCurrentNaviTargetUrls: [`${baseUrl}report/funnel`],
        dataId: 'funnel-reports',
      },
    ]
  }, [locationSearch])

  const captureMenu = React.useMemo(
    () => [
      { name: '自動クローラー設定', url: `${baseUrl}capture/control`, dataId: 'control' },
      { name: 'キャプチャ履歴', url: `${baseUrl}capture/history`, dataId: 'history' },
    ],
    [],
  )

  const projectSettingMenu = React.useMemo(
    () => [
      {
        name: 'プロジェクト情報',
        url: `${baseUrl}settings/project`,
        matchCurrentNaviTargetUrls: [`${baseUrl}settings/trackingcode`],
        dataId: 'project',
      },
      { name: 'ゴール', url: `${baseUrl}settings/goal`, dataId: 'goal' },
      { name: 'IPアドレスフィルター', url: `${baseUrl}settings/ip_address`, dataId: 'ip_address' },
      { name: 'クロスドメイン', url: `${baseUrl}settings/cross_domain`, dataId: 'cross_domain' },
      { name: 'カスタムディメンション', url: `${baseUrl}settings/dimension`, dataId: 'dimension' },
      { name: 'クエリーパラメータ', url: `${baseUrl}settings/query`, dataId: 'query' },
      { name: 'PC・Mobile振り分け', url: `${baseUrl}settings/site_responsive`, dataId: 'site_responsive' },
      { name: 'メンバー', url: `${baseUrl}settings/member`, dataId: 'member' },
    ],
    [],
  )

  // 必要なアカウント情報が取得できた後実行される
  React.useEffect(() => {
    if (checking && AccountInfo.userId !== 0 && AccountInfo.projectId !== 0) {
      actions.setProject(AccountInfo.userId, AccountInfo.projectId, AccountInfo.permissions, baseUrl!)
    } else if (!checking && (AccountInfo.userId !== userId || AccountInfo.projectId !== projectId)) {
      actions.setProject(AccountInfo.userId, AccountInfo.projectId, AccountInfo.permissions, baseUrl!)
    }
  }, [AccountInfo.userId, AccountInfo.projectId, AccountInfo.permissions])

  React.useEffect(() => {
    if (isIconClickedOnce) return
    isContentReportPage(pathname) && closeNavigation()
  }, [])

  const supportFormUrl = AccountInfo.teamCode
    ? `${SUPPORT_FORM_LINK}?ca_support_form_organizer_code=${AccountInfo.teamCode}`
    : `${SUPPORT_FORM_LINK}`

  //ナビをホバーで開く。アイコンのクリックで開いてる時は何もしない。
  const navMouseEnter = () => !clicked && hoverNavigation()

  const iconClick = () => {
    if (hovered) {
      clickNavigation()
      hoverNavigation()
    } else if (!opened) {
      clickNavigation()
    } else {
      closeNavigation()
    }
  }

  // バックドロップにマウスが触れたら閉じる。画面遷移時にloadingのoverlayが上に来るためその間にナビからマウスが離れた場合の対策。
  const backdropMouseEnter = React.useCallback(() => closeNavigation(), [])

  return (
    <>
      {!checking && modalOpened && (
        <ProjectListModal
          opened={modalOpened}
          userId={AccountInfo.userId}
          projectId={projectId}
          canAddProject={Authority.canAddProject(permissions)}
          canViewAddProject={Authority.canViewAddProject(permissions)}
          adminLayout={adminModal}
          newProject={actions.newProject}
          onCancel={actions.onCancel}
        />
      )}
      {/* プロジェクト新規作成 */}
      {!checking && newModalOpened && (
        <div>
          {createStep === createProjectStep.create && (
            <ProjectModal
              opened={createStep === createProjectStep.create}
              onCreate={actions.createProject}
              onClose={actions.closeProject}
            />
          )}
          {createStep === createProjectStep.confirm && (
            <ConfirmModal
              isOpen={createStep === createProjectStep.confirm}
              title="新規プロジェクトの作成"
              children={
                <>
                  <div>プロジェクトを追加しました。</div>
                  <Link href="/admin/captureLimit" target="_blank" rel="noopener" style={{ marginTop: '1rem' }}>
                    こちらからページキャプチャの取得上限設定を行ってください。
                  </Link>
                  <div style={{ marginTop: '1rem' }}>
                    ページキャプチャの取得上限設定を行うことで計測が開始されます。
                  </div>
                </>
              }
              onClose={actions.confirmProject} // 閉じたらプロジェクトページへ遷移する
            />
          )}
        </div>
      )}
      <Container className={openOrCloseClass}>
        <MenuBox
          onMouseEnter={navMouseEnter}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90px',
          }}
        >
          {opened ? (
            <Logo size="small" linked style={{ padding: '21px 0' }} />
          ) : (
            <div style={{ width: '50px', cursor: 'pointer', padding: '1.2rem 0', textAlign: 'center' }}>
              <img data-testid="short-logo" src={smallIcon} onClick={() => navigate('/')} />
            </div>
          )}
        </MenuBox>
        <MenuBox
          onMouseEnter={navMouseEnter}
          style={{
            ...(!opened && {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }),
            flex: '1',
          }}
          className={cc([{ adminLayout }, { opened }, process.env.NODE_ENV])}
        >
          {/* プロジェクト情報 */}
          {!adminLayout && (
            <ProjectBox opened={opened}>
              {errorMessage !== '' ? (
                <ErrorMessage>{errorMessage}</ErrorMessage>
              ) : (
                AccountInfo.projectId !== 0 && (
                  <div
                    data-testid="change-project-box"
                    style={{
                      width: '100%',
                      ...(!opened && {
                        display: 'flex',
                        justifyContent: 'center',
                      }),
                    }}
                  >
                    {opened && <ProjectNameBox>{AccountInfo.projectName}</ProjectNameBox>}
                    <div
                      style={{
                        textAlign: 'right',
                        overflow: 'hidden',
                        ...(!opened && {
                          minHeight: '84px',
                          display: 'flex',
                          alignItems: 'center',
                        }),
                      }}
                    >
                      <StyledRepost size={ICON_SIZE} />
                      {opened && <ProjectLink onClick={actions.openModal}>プロジェクト切り替え</ProjectLink>}
                    </div>
                  </div>
                )
              )}
            </ProjectBox>
          )}
          <Menus className={cc([{ adminLayout }])} opened={opened}>
            {/* 管理ページ以外用表示 */}
            {!adminLayout && (
              <ItemBox>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  {/* レポート */}
                  {AccountInfo.projectId !== 0 && (
                    <>
                      <StyledAccordion expanded={reportOpen} defaultExpanded={reportOpen} disableGutters>
                        <StyledAccordionSummary
                          expandIcon={<StyledCaretLeft size={ICON_SIZE} />}
                          onClick={() =>
                            dispatch(onNaviAccordionChange({ accordionName: 'reportOpen', open: !reportOpen }))
                          }
                        >
                          <Title>
                            <AccordionIcon>
                              <ReportIcon size={ICON_SIZE} color={PROJECT_ICON_COLOR} />
                            </AccordionIcon>
                            <MenuTitle>レポート</MenuTitle>
                          </Title>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                          {reportMenu.map((menu) => {
                            return (
                              <NavigationButtonNarrow
                                key={menu.name}
                                type="button"
                                data-id={menu.dataId}
                                iconLeft={<StyledDot size={ICON_SIZE} opacity={!opened ? 1 : 0} />}
                                to={menu.url}
                                className={cc([
                                  {
                                    isCurrent: isCurrentMenu({
                                      pathname: pathname,
                                      menuUrl: menu.url,
                                      menuUrlPatterns: menu.matchCurrentNaviTargetUrls,
                                    }),
                                  },
                                ])}
                              >
                                <MenuText>{menu.name}</MenuText>
                              </NavigationButtonNarrow>
                            )
                          })}
                        </StyledAccordionDetails>
                      </StyledAccordion>
                    </>
                  )}

                  {/* ページキャプチャ */}
                  {/* 表示権限が必要 */}
                  {Authority.canViewPageCapture(permissions, projectId) && AccountInfo.projectId !== 0 && (
                    <StyledAccordion expanded={captureOpen} defaultExpanded={captureOpen} disableGutters>
                      <StyledAccordionSummary
                        expandIcon={<StyledCaretLeft size={ICON_SIZE} />}
                        onClick={() =>
                          dispatch(onNaviAccordionChange({ accordionName: 'captureOpen', open: !captureOpen }))
                        }
                      >
                        <Title>
                          <AccordionIcon>
                            <StyledCamera size={ICON_SIZE} />
                          </AccordionIcon>
                          <MenuTitle>ページキャプチャ</MenuTitle>
                        </Title>
                      </StyledAccordionSummary>
                      <StyledAccordionDetails>
                        {captureMenu.map((menu) => {
                          return (
                            <NavigationButtonNarrow
                              key={menu.name}
                              type="button"
                              data-id={menu.dataId}
                              iconLeft={<StyledDot size={ICON_SIZE} opacity={!opened ? 1 : 0} />}
                              to={menu.url}
                              className={cc([
                                {
                                  isCurrent: isCurrentMenu({
                                    pathname: pathname,
                                    menuUrl: menu.url,
                                  }),
                                },
                              ])}
                            >
                              <MenuText>{menu.name}</MenuText>
                            </NavigationButtonNarrow>
                          )
                        })}
                      </StyledAccordionDetails>
                    </StyledAccordion>
                  )}

                  {/* プロジェクト設定 */}
                  {/* 表示権限が必要 */}
                  {Authority.canViewProjectSettings(permissions, projectId) && (
                    <StyledAccordion expanded={projectSettingOpen} defaultExpanded={projectSettingOpen} disableGutters>
                      <StyledAccordionSummary
                        expandIcon={<StyledCaretLeft size={ICON_SIZE} />}
                        onClick={() => {
                          dispatch(
                            onNaviAccordionChange({
                              accordionName: 'projectSettingOpen',
                              open: !projectSettingOpen,
                            }),
                          )
                        }}
                      >
                        <Title>
                          <AccordionIcon>
                            <StyledSettings size={ICON_SIZE} />
                          </AccordionIcon>
                          <MenuTitle>プロジェクト設定</MenuTitle>
                        </Title>
                      </StyledAccordionSummary>
                      <StyledAccordionDetails>
                        {projectSettingMenu.map((menu) => {
                          return (
                            <NavigationButtonNarrow
                              key={menu.name}
                              type="button"
                              data-id={menu.dataId}
                              iconLeft={<StyledDot size={ICON_SIZE} opacity={!opened ? 1 : 0} />}
                              to={menu.url}
                              className={cc([
                                {
                                  isCurrent: isCurrentMenu({
                                    pathname: pathname,
                                    menuUrl: menu.url,
                                    menuUrlPatterns: menu.matchCurrentNaviTargetUrls,
                                  }),
                                },
                              ])}
                            >
                              <MenuText>{menu.name}</MenuText>
                            </NavigationButtonNarrow>
                          )
                        })}
                      </StyledAccordionDetails>
                    </StyledAccordion>
                  )}

                  {/* 外部データインポート */}
                  <NavigationButton
                    type="button"
                    data-id="data-import"
                    iconLeft={<StyledFileImport size={ICON_SIZE} />}
                    to={`${baseUrl}data-import`}
                    className={cc([
                      {
                        isCurrent: isCurrentMenu({
                          pathname: pathname,
                          menuUrl: `${baseUrl}data-import`,
                        }),
                      },
                    ])}
                  >
                    <MenuTitle>外部データインポート</MenuTitle>
                  </NavigationButton>
                </div>
              </ItemBox>
            )}

            {/* 管理ページ用表示 */}
            {adminLayout && (
              <ItemBox className={cc([{ adminLayout }, process.env.NODE_ENV])} data-testid="admin-menu">
                {/* 組織管理ページ用表示 */}
                {!accountLayout && (
                  <div
                    data-testid="admin-organization-menu"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {ADMIN_NAVIGATION_MENUS.map((menu) => {
                      return (
                        <NavigationButton
                          key={menu.name}
                          className={cc([
                            {
                              adminLayout,
                              isCurrent: isCurrentMenu({
                                pathname: pathname,
                                menuUrl: menu.url,
                                menuUrlPatterns: menu.matchCurrentNaviTargetUrls,
                              }),
                            },
                          ])}
                          type="button"
                          data-id={menu.dataId}
                          iconLeft={menu.icon}
                          to={menu.url}
                        >
                          <MenuTitle>{menu.name}</MenuTitle>
                        </NavigationButton>
                      )
                    })}
                  </div>
                )}
              </ItemBox>
            )}

            <HelpBox>
              <DesignedLinkBox data-id="accountSetting" onClick={actions.onAccountClick}>
                <StyledUser size={ICON_SIZE} />
                <MenuText>アカウント管理</MenuText>
              </DesignedLinkBox>

              <DesignedLink href={HELP_LINKS.TOP} target="_blank" rel="noopener">
                <StyledHelpCircle size={ICON_SIZE} />
                <MenuText>ヘルプ</MenuText>
              </DesignedLink>

              <DesignedLink href={HELP_LINKS.NEWS_RELEASE} target="_blank" rel="noopener">
                <StyledNotificationsNone size={ICON_SIZE} />
                <MenuText>新着情報・お知らせ</MenuText>
              </DesignedLink>

              <DesignedLink href={BLOG_URL_TOP} target="_blank" rel="noopener">
                <HelpBoxIconWrapper>
                  <BlogIcon size={ICON_SIZE} color={colors.lightBlue} />
                </HelpBoxIconWrapper>
                <MenuText>活用ブログ</MenuText>
              </DesignedLink>

              <DesignedLink href={HELP_LINKS.DOCUMENT_DOWNLOAD} target="_blank" rel="noopener">
                <StyledFile size={ICON_SIZE} />
                <MenuText>ドキュメント</MenuText>
              </DesignedLink>

              <DesignedLink href={supportFormUrl} target="_blank" rel="noopener">
                <StyledNotepadEdit size={ICON_SIZE} />
                <MenuText>お問い合わせ</MenuText>
              </DesignedLink>
            </HelpBox>

            {accountMenuOpened && (
              <AccountMenu
                name={AccountInfo.userName}
                naviOpened={opened}
                admin={Authority.canViewTeamSettings(permissions)}
                toAccount={actions.toAccount}
                toAdmin={actions.toAdmin}
                logout={actions.logout}
                onClose={actions.onAccountClose}
              />
            )}
          </Menus>
        </MenuBox>
        <MenuBox>
          {/* 開閉アイコン */}
          <IconBox className={openOrCloseClass}>
            {clicked ? (
              <CloseIcon onClick={iconClick} data-testid="left-arrow-icon" />
            ) : hovered ? (
              <OpenIcon onClick={iconClick} data-testid="right-arrow-icon" />
            ) : (
              <OpenIcon onClick={iconClick} data-testid="right-arrow-icon" />
            )}
          </IconBox>
        </MenuBox>
      </Container>
      <NavigationBackdrop onMouseOver={backdropMouseEnter} isOpenedByHover={hovered} data-testid="backdrop" />
    </>
  )
}

const ICON_SIZE = 20
const PROJECT_ICON_COLOR = colors.lightBlue

const StyledInfoCircle = styled(InfoCircle)`
  flex-shrink: 0;
  color: ${colors.orange};
`
const StyledLayoutTextWindowReverse = styled(LayoutTextWindowReverse)`
  flex-shrink: 0;
  color: ${colors.orange};
`
const StyledUsers = styled(Users)`
  flex-shrink: 0;
  color: ${colors.orange};
`
const StyledList = styled(List)`
  flex-shrink: 0;
  color: ${colors.orange};
`
const StyledLaptopDismiss = styled(LaptopDismiss)`
  flex-shrink: 0;
  color: ${colors.orange};
`
const StyledFileExport = styled(FileExport)`
  flex-shrink: 0;
  color: ${colors.orange};
`

const ADMIN_NAVIGATION_MENUS = [
  {
    name: '基本情報',
    url: '/admin/info',
    matchCurrentNaviTargetUrls: ['/admin/captureLimit'],
    dataId: 'info',
    icon: <StyledInfoCircle size={ICON_SIZE} />,
  },
  {
    name: 'プロジェクト',
    url: '/admin/project',
    dataId: 'project',
    icon: <StyledLayoutTextWindowReverse size={ICON_SIZE} />,
  },
  {
    name: 'メンバー',
    url: '/admin/member',
    dataId: 'member',
    icon: <StyledUsers size={ICON_SIZE} />,
  },
  {
    name: 'ログ',
    url: '/admin/audit',
    dataId: 'audit',
    icon: <StyledList size={ICON_SIZE} />,
  },
  {
    name: 'IPアドレス制限',
    url: '/admin/ipLimit',
    dataId: 'ipLimit',
    icon: <StyledLaptopDismiss size={ICON_SIZE} />,
  },
  {
    name: 'データエクスポート',
    url: '/admin/export',
    dataId: 'export',
    icon: <StyledFileExport size={ICON_SIZE} />,
  },
]

const Container = styled.nav`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
  width: ${layout.navigationWidth};
  max-width: ${layout.navigationWidth};
  border-right: 1px solid ${colors.gray300};
  z-index: ${layout.navigationZIndex};
  background-color: ${colors.white};
  transition: width 180ms cubic-bezier(0, 1, 0.8, 1) 0ms, max-width 180ms cubic-bezier(0, 1, 0.8, 1) 0ms;
  &.close {
    transition: width 180ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, max-width 180ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    width: ${layout.navigationCloseWidth};
    max-width: ${layout.navigationCloseWidth};
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    height: 100vh;
  }
`

const MenuBox = styled.div`
  height: auto;
  width: 100%;
`

const Menus = styled.div<{ opened: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 218px);
  max-height: calc(100vh - 218px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    background: ${(props) => (props.opened ? '' : 'transparent')};
    width: ${(props) => (props.opened ? '' : 0)};
  }
  &.adminLayout {
    height: 100%;
    max-height: 100%;
  }
`

const ProjectBox = styled.div<{ opened: boolean }>`
  width: calc(100% - 16px);
  word-break: break-all;
  display: flex;
  align-items: center;
  height: 84px;
  margin: 0 auto 1rem;
  padding: ${(props) => (props.opened ? '12px 1.4rem' : '0')};
  background-color: ${colors.contentBlue.blue1};
`

const ProjectNameBox = styled.div`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  max-height: 40px;
`

const StyledRepost = styled(Repost)`
  color: ${colors.lightBlue};
`

const ProjectLink = styled.a`
  font-size: 12px;
  text-align: right;
  text-decoration: none;
  cursor: pointer;
  color: ${colors.link.base};
  font-weight: bold;
  padding: 0 0.8rem 0 0;

  &:visited {
    color: ${colors.link.visited};
  }
`

const linkBase = css`
  color: ${colors.link.base};
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 4px 14px;
`

const DesignedLink = styled.a`
  ${linkBase}
  &:hover {
    color: ${colors.lightBlue};
  }
`

const DesignedLinkBox = styled.div`
  ${linkBase}
  &:hover {
    color: ${colors.lightBlue};
  }
`

interface LinkProps extends React.ButtonHTMLAttributes<any> {
  readonly iconLeft?: JSX.Element
  readonly to: string
}

const base = css`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 600;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  outline: none;
  text-decoration: none;
  border-style: none;
  border-width: 2px;
  opacity: 1;
  color: ${colors.black};
  background-color: ${colors.white};
  border-color: ${colors.gray300};
  transition: opacity filter 0.3s;
  width: 100%;
  font-size: 14px;
  box-shadow: none;
  padding: 8px 14px;
  position: relative;
  overflow: hidden;

  &:hover,
  &:focus {
    opacity: 1;
    background-color: ${colors.contentBlue.blue1};
    &.adminLayout {
      background-color: ${colors.lightSalmon};
    }
    &.isCurrent {
      color: ${colors.white};
      background-color: ${colors.contentBlue.blue5};
      &.adminLayout {
        background-color: ${colors.contentOrange.orange6};
      }
    }
  }

  &.isCurrent {
    color: ${colors.white};
    background-color: ${colors.contentBlue.blue5};
    [data-icon='left'] {
      color: currentColor;
    }
    &.adminLayout {
      background-color: ${colors.contentOrange.orange6};
    }
  }

  &:active {
    filter: brightness(1);
  }

  &:disabled {
    background-color: ${colors.disabled};
  }

  [data-icon='left'] {
    display: inline-block;
    margin-right: 1rem;
  }

  [data-icon='right'] {
    display: inline-block;
    margin-left: 1rem;
  }
`

const LinkButton = styled((props: LinkProps) => <RouterLink {...props} />)`
  ${base}
`

const NavigationButton = ({ iconLeft, children, ...rest }: LinkProps) => (
  <LinkButton {...rest}>
    {iconLeft && React.cloneElement(iconLeft, { 'data-icon': 'left' })}
    {children}
  </LinkButton>
)

const NavigationButtonNarrow = styled(NavigationButton)`
  padding: 4px 14px;
`

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &.adminLayout {
    padding-top: 1rem;
  }
`

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  border-top: 1px solid ${colors.gray300};

  &.open {
    justify-content: flex-end;
    padding-right: 1rem;
    margin-left: -1rem;
  }
`

const OpenIcon = styled(ChevronsRight).attrs({
  size: 24,
  color: `${colors.gray400}`,
})`
  cursor: pointer;
  border-radius: 30px;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.lightBlue};
  }
`

const CloseIcon = styled(ChevronsLeft).attrs({
  size: 24,
  color: `${colors.gray400}`,
})`
  cursor: pointer;
  border-radius: 30px;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.lightBlue};
  }
`

const ErrorMessage = styled.p`
  color: ${colors.error};
`

const NavigationBackdrop = styled.div<{ isOpenedByHover: boolean }>`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpenedByHover }) => (isOpenedByHover ? 1 : 0)};
  z-index: ${({ isOpenedByHover }) => (isOpenedByHover ? layout.navigationBackdropZIndex : -100)};
  will-change: ${({ isOpenedByHover }) => (isOpenedByHover ? 'opacity, z-index' : 'auto')};
  transition: opacity 185ms cubic-bezier(0.7, 0.8, 0.9, 1) 0ms, z-index 200ms cubic-bezier(0.7, 0.8, 0.9, 1) 0ms;
`

const MenuText = styled.p`
  margin: 0;
  left: 48px;
  position: absolute;
  font-size: 12px;
  font-weight: bold;
`

const MenuTitle = styled(MenuText)`
  font-size: 14px;
`

const StyledAccordion = styled(Accordion)`
  width: 100%;
  background: none;
  box-shadow: none;
  &:before {
    opacity: 0;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  min-height: 34px;
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin-right: 10px;
    &.Mui-expanded {
      transform: rotate(-90deg);
    }
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`

const StyledCaretLeft = styled(CaretLeft)`
  color: ${colors.black};
`

const StyledDot = styled(Dot)`
  flex-shrink: 0;
  color: ${colors.lightBlue};
`

const StyledCamera = styled(Camera)`
  color: ${PROJECT_ICON_COLOR};
`

const StyledSettings = styled(Settings)`
  color: ${PROJECT_ICON_COLOR};
`

const AccordionIcon = styled.div`
  flex-shrink: 0;
  margin-right: 14px; /* サイドバーが閉じた時に右の折りたたみキャレット「◀」を隠すためにmargin-rightが必要 */
`

const StyledFileImport = styled(FileImport)`
  flex-shrink: 0;
  color: ${PROJECT_ICON_COLOR};
`

const HelpBox = styled.div`
  margin-top: 24px;
`

const StyledHelpCircle = styled(HelpCircle)`
  margin-right: 1rem;
  flex-shrink: 0;
  color: ${colors.lightBlue};
`

const StyledFile = styled(File)`
  margin-right: 1rem;
  flex-shrink: 0;
  color: ${colors.lightBlue};
`

const StyledNotepadEdit = styled(NotepadEdit)`
  margin-right: 1rem;
  flex-shrink: 0;
  color: ${colors.lightBlue};
`

const StyledNotificationsNone = styled(NotificationsNone)`
  margin-right: 1rem;
  flex-shrink: 0;
  color: ${colors.lightBlue};
`

const HelpBoxIconWrapper = styled.div`
  margin-right: 1rem;
  flex-shrink: 0;
`

const StyledUser = styled(User)`
  margin-right: 1rem;
  flex-shrink: 0;
  color: ${colors.lightBlue};
`

/**
 * 現在のURLパスがメニューに対応するかどうかを判定する
 *
 * @param {Object} Props - The properties object.
 * @param {string} Props.pathname - The current pathname.
 * @param {string} Props.menuUrl - The menu URL. exact match.
 * @param {string[]} Props.menuUrlPatterns - The array of URL patterns for the menu. forward match.
 *
 * @returns {boolean} - Indicates whether the current menu is active.
 */
const isCurrentMenu = ({
  pathname,
  menuUrl,
  menuUrlPatterns,
}: {
  pathname: string
  menuUrl?: string
  menuUrlPatterns?: string[]
}): boolean => {
  return pathname === menuUrl || menuUrlPatterns?.some((url) => pathname.indexOf(url) === 0) || false
}
