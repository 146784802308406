// invalidateQueriesで指定するKeyは重複しないようにこちらで一元管理する
export const PAGE_REPORT_QUERY_KEY = 'PageReport'
export const CONTENT_REPORT_QUERY_KEY = 'ContentReport'
export const CONTENT_GROUP_REPORT_QUERY_KEY = 'ContentGroupReport'
export const TIMELINE_REPORT_QUERY_KEY = 'TimelineReport'
export const AVAILABLE_PAGE_LAYOUTS_QUERY_KEY = 'AvailablePageLayouts'
export const URL_LIMIT_QUERY_KEY = 'UrlLimit'
export const PROJECT_PV_QUERY_KEY = 'ProjectPv'
export const PROJECT_QUERY_KEY = 'Project'
export const TEAM_PV_QUERY_KEY = 'TeamPv'
export const TEAM_QUERY_KEY = 'Team'
export const CUSTOM_DIMENSION_REPORT_QUERY_KEY = 'CustomDimensionReport'
export const CUSTOM_DIMENSION_VALUE_REPORT_QUERY_KEY = 'CustomDimensionValueReport'
export const TRAFFIC_REPORT_QUERY_KEY = 'TrafficReport'
export const DATA_IMPORT_SCHEMAS_QUERY_KEY = 'DataImportSchemas'
export const DATA_IMPORT_ALL_KEY_FIELDS_QUERY_KEY = 'DataImportAllKeyFields'
export const DATA_IMPORT_CSV_QUERY_KEY = 'DataImportCsv'
export const DATA_IMPORT_SCHEDULE_QUERY_KEY = 'DataImportSchedule'
export const DATA_IMPORT_LOGS_QUERY_KEY = 'DataImportLogs'
export const LATEST_IMPORT_DATA_QUERY_KEY = 'LatestImportData'
export const REPORT_FILTER_SELECTS_QUERY_KEY = 'ReportFilterSelectsQueryKey'
export const GOAL_LIST_QUERY_KEY = 'GoalListQueryKey'
export const FILTER_TEXT_TRANSFORM = 'filterTextTransform'
export const FUNNEL_REPORT_QUERY_KEY = 'FunnelReport'
export const FUNNEL_REPORTS_QUERY_KEY = 'FunnelReports'
