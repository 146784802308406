import { getBaseURL } from '../../env'
import { SortItemKey, SortState } from '../../util/hooks/api/useContentReport'
import { SORT_ORDER, SortOrder } from '../../util/sortBy'
import { SORT_ICONS } from '../common/SortButton'

export const GRID_MIN_WIDTH = 942
export const GRID_ITEM_HEIGHT = 36
export const REGISTER_FOOTER_HEIGHT = 48
export const GRID_TEMPLATE_COLUMNS = '108px minmax(134px, 1fr) 155px 106px 72px 72px 72px 106px 72px 72px'
export const GRID_HEADER_ROWS_MIN_HEIGHT = '107px'
export const GRID_HEADER_ROWS_CHILD_HEIGHT = '34px 36px 36px'

const SCROLLBAR_WIDTH = 14
export const SELECTION_AREA_HORIZONTAL_PADDING = 40
export const CAPTURE_AREA_ALL_PADDING = SCROLLBAR_WIDTH + SELECTION_AREA_HORIZONTAL_PADDING * 2
export const CAPTURE_AREA_MIN_WIDTH = 7 + CAPTURE_AREA_ALL_PADDING // pageCaptureAreaWidthが 7px までは縮小可能
export const CAPTURE_AREA_DEFAULT_WIDTH = 270 + CAPTURE_AREA_ALL_PADDING
export const CAPTURE_AREA_LABEL_HIDE_WIDTH = 103 + CAPTURE_AREA_ALL_PADDING // pageCaptureAreaWidthが 103px + CAPTURE_AREA_ALL_PADDING = 200px未満で非表示

export const LOWEST_RANK_TO_SHOW = 10

export const CONTENT_GROUP_REPORT_HOWTO_MOVIE_URL = `${getBaseURL()}/media/movies/contentGroupReportHowTo.mp4`

export const extractContentReportSortValue = (
  sortState: SortState,
): {
  sortKey: SortItemKey | null
  order: SortOrder
} => {
  const sortObj = Object.entries(sortState).find(([_, value]) => value !== 'none')
  if (!sortObj) return { sortKey: null, order: SORT_ORDER.DESC }

  const [sortKey, sortIcon] = sortObj
  return {
    sortKey: sortKey as SortItemKey,
    order: sortIcon === SORT_ICONS.DOWN ? SORT_ORDER.DESC : SORT_ORDER.ASC,
  }
}

// TODO: pages.ContentReportの記述は極力こちらに移動する。（余力ある時でOK）
