import { DimensionKeys, DimensionMatchConditions, DimensionOption } from './types'

export const AGGREGATE_SCOPES = {
  USER: 'user',
  SESSION: 'session',
  PAGE_VIEW: 'page_view',
} as const
export const aggregateScopeOptions = [
  { value: AGGREGATE_SCOPES.USER, label: 'ユーザー' },
  { value: AGGREGATE_SCOPES.SESSION, label: 'セッション' },
  { value: AGGREGATE_SCOPES.PAGE_VIEW, label: 'ヒット(ページビュー)' },
]

export const enum DIMENSION_KEYS {
  VISITS = 'visits',
  SESSION_NO = 'session_no',
  BROWSING_PAGE = 'viewed_page_',
  LANDING_PAGE = 'landing_page_',
  IS_SESSION_START_PAGE = 'is_session_start_page',
  PV_SEQUENCE = 'pv_sequence',
  IS_SESSION_EXIT_PAGE = 'is_session_exit_page',
  USER = 'user_id',
  CUSTOM_DIMENSION = 'custom_dimension',
  DATA_IMPORT_FIELD = 'data_import_field',
  GOAL = 'goal',
  CHANNEL = 'channel',
  FIRST_TRAFFIC_MEDIUM = 'first_traffic_medium',
  FIRST_TRAFFIC_SOURCE = 'first_traffic_source',
  FIRST_TRAFFIC_CAMPAIGN = 'first_traffic_campaign',
}

export const DIMENSION_PAGE_TARGET_COLUMN_KEY_TYPES: Array<DimensionKeys> = [
  DIMENSION_KEYS.BROWSING_PAGE,
  DIMENSION_KEYS.LANDING_PAGE,
]

export const DIMENSION_CATEGORIES = {
  ACTION: '行動',
  PAGE: 'ページ',
  USER: 'ユーザー',
  TRAFFIC: '流入',
  CUSTOM_DIMENSION: 'カスタムディメンション',
  DATA_IMPORT_FIELD: 'インポートデータ',
  GOAL: 'ゴール',
} as const

export const CONDITION_TYPES = {
  NUMERIC: 'numeric', // 数値系(選択肢にEmpty系含まない)
  NUMERIC_WITH_EMPTY: 'numeric_with_empty', // 数値系(選択肢にEmpty系含む)
  STRING: 'string', // 文字列系(選択肢にEmpty系含まない)
  STRING_WITH_EMPTY: 'string_with_empty', // 文字列系(選択肢にEmpty系含む)
  DATE_WITH_EMPTY: 'date_with_empty', // 日付系(選択肢にEmpty系含む)
  CHOICE: 'choice', // 種類系
  BOOL: 'bool', // bool系の条件リスト
} as const

// 各ディメンションと対応する集計スコープ(targetScopes)のマッピングは以下ドキュメント参照
// see. https://tracery.jp/s/f8d136a8401e4e47aa945d9fc33454ee
export const DEFAULT_DIMENSION_OPTIONS: DimensionOption[] = [
  {
    id: DIMENSION_KEYS.VISITS,
    name: '訪問回数',
    dimensionCategory: DIMENSION_CATEGORIES.ACTION,
    conditionType: CONDITION_TYPES.NUMERIC,
    targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
  },
  {
    id: DIMENSION_KEYS.SESSION_NO,
    name: '期間内セッションNo',
    dimensionCategory: DIMENSION_CATEGORIES.ACTION,
    conditionType: CONDITION_TYPES.NUMERIC,
    targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
  },
  {
    id: DIMENSION_KEYS.IS_SESSION_START_PAGE,
    name: 'セッション内で1番目に見たページ(ランディングページ)',
    dimensionCategory: DIMENSION_CATEGORIES.PAGE,
    conditionType: CONDITION_TYPES.BOOL,
    targetScopes: [AGGREGATE_SCOPES.PAGE_VIEW],
  },
  {
    id: DIMENSION_KEYS.PV_SEQUENCE,
    name: 'セッション内でX番目に見たページ',
    dimensionCategory: DIMENSION_CATEGORIES.PAGE,
    conditionType: CONDITION_TYPES.NUMERIC,
    targetScopes: [AGGREGATE_SCOPES.PAGE_VIEW],
  },
  {
    id: DIMENSION_KEYS.IS_SESSION_EXIT_PAGE,
    name: 'セッション内で最後に見たページ(離脱ページ)',
    dimensionCategory: DIMENSION_CATEGORIES.PAGE,
    conditionType: CONDITION_TYPES.BOOL,
    targetScopes: [AGGREGATE_SCOPES.PAGE_VIEW],
  },
  {
    id: DIMENSION_KEYS.BROWSING_PAGE,
    name: '閲覧ページ',
    dimensionCategory: DIMENSION_CATEGORIES.PAGE,
    conditionType: CONDITION_TYPES.STRING,
    targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION, AGGREGATE_SCOPES.PAGE_VIEW],
  },
  {
    id: DIMENSION_KEYS.LANDING_PAGE,
    name: 'ランディングページ',
    dimensionCategory: DIMENSION_CATEGORIES.PAGE,
    conditionType: CONDITION_TYPES.STRING,
    targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
  },
  {
    id: DIMENSION_KEYS.USER,
    name: 'ユーザーID',
    dimensionCategory: DIMENSION_CATEGORIES.USER,
    conditionType: CONDITION_TYPES.STRING_WITH_EMPTY,
    targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
  },
  {
    id: DIMENSION_KEYS.CHANNEL,
    name: 'チャネル',
    dimensionCategory: DIMENSION_CATEGORIES.TRAFFIC,
    conditionType: CONDITION_TYPES.STRING,
    targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
  },
  {
    id: DIMENSION_KEYS.FIRST_TRAFFIC_SOURCE,
    name: '参照元',
    dimensionCategory: DIMENSION_CATEGORIES.TRAFFIC,
    conditionType: CONDITION_TYPES.STRING,
    targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
  },
  {
    id: DIMENSION_KEYS.FIRST_TRAFFIC_MEDIUM,
    name: 'メディア',
    dimensionCategory: DIMENSION_CATEGORIES.TRAFFIC,
    conditionType: CONDITION_TYPES.STRING_WITH_EMPTY,
    targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
  },
  {
    id: DIMENSION_KEYS.FIRST_TRAFFIC_CAMPAIGN,
    name: 'キャンペーン',
    dimensionCategory: DIMENSION_CATEGORIES.TRAFFIC,
    conditionType: CONDITION_TYPES.STRING_WITH_EMPTY,
    targetScopes: [AGGREGATE_SCOPES.USER, AGGREGATE_SCOPES.SESSION],
  },
]

export const AGGREGATE_CONDITIONS = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
} as const
export const aggregateConditionOptions = [
  { value: AGGREGATE_CONDITIONS.INCLUDE, label: '含める' },
  { value: AGGREGATE_CONDITIONS.EXCLUDE, label: '除外する' },
]

export const DIMENSION_PAGE_TARGET_COLUMNS = {
  URL: 'url',
  TITLE: 'title',
} as const
export const dimensionPageTargetColumnOptions = [
  { value: DIMENSION_PAGE_TARGET_COLUMNS.URL, label: 'URL' },
  { value: DIMENSION_PAGE_TARGET_COLUMNS.TITLE, label: 'タイトル' },
]

export const DIMENSION_MATCH_CONDITIONS = {
  // string系
  FULL: 'full',
  PARTIAL: 'partial',
  HEAD: 'head',
  TAIL: 'tail',
  REGEXP: 'regexp',
  NOT_FULL: 'not_full',
  NOT_PARTIAL: 'not_partial',
  NOT_HEAD: 'not_head',
  NOT_TAIL: 'not_tail',
  NOT_REGEXP: 'not_regexp',
  // number系
  EQ: 'eq',
  NOT_EQ: 'not_eq',
  GT: 'gt',
  GE: 'ge',
  LT: 'lt',
  LE: 'le',
  // date系
  DATE_BETWEEN: 'date_between',
  DATE_EQ: 'date_eq',
  DATE_LE: 'date_le',
  DATE_GE: 'date_ge',
  // choice系
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
  // bool系
  BOOL_EQ: 'bool_eq',
  BOOL_NOT_EQ: 'bool_not_eq',
  // between系
  BETWEEN: 'between',
  // empty系
  EMPTY: 'empty',
  NOT_EMPTY: 'not_empty',
} as const

export const DIMENSION_BETWEEN_TYPES: Array<DimensionMatchConditions> = [
  DIMENSION_MATCH_CONDITIONS.BETWEEN,
  DIMENSION_MATCH_CONDITIONS.DATE_BETWEEN,
]

export const DIMENSION_EMPTY_TYPES: Array<DimensionMatchConditions> = [
  DIMENSION_MATCH_CONDITIONS.EMPTY,
  DIMENSION_MATCH_CONDITIONS.NOT_EMPTY,
]

export const DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS = {
  EQ: DIMENSION_MATCH_CONDITIONS.EQ,
  NOT_EQ: DIMENSION_MATCH_CONDITIONS.NOT_EQ,
  GT: DIMENSION_MATCH_CONDITIONS.GT,
  GE: DIMENSION_MATCH_CONDITIONS.GE,
  LT: DIMENSION_MATCH_CONDITIONS.LT,
  LE: DIMENSION_MATCH_CONDITIONS.LE,
  BETWEEN: DIMENSION_MATCH_CONDITIONS.BETWEEN,
  EMPTY: DIMENSION_MATCH_CONDITIONS.EMPTY,
  NOT_EMPTY: DIMENSION_MATCH_CONDITIONS.NOT_EMPTY,
} as const
export const dimensionNumericWithEmptyConditionOptions = [
  { value: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.EQ, label: '等しい' },
  { value: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.NOT_EQ, label: '異なる' },
  { value: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.GT, label: 'より大きい' },
  { value: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.GE, label: '以上' },
  { value: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.LT, label: 'より小さい' },
  { value: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.LE, label: '以下' },
  { value: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.BETWEEN, label: '間' },
  { value: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.EMPTY, label: '空を含む' },
  { value: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.NOT_EMPTY, label: '空を含まない' },
]

export const DIMENSION_NUMERIC_CONDITIONS = {
  EQ: DIMENSION_MATCH_CONDITIONS.EQ,
  NOT_EQ: DIMENSION_MATCH_CONDITIONS.NOT_EQ,
  GT: DIMENSION_MATCH_CONDITIONS.GT,
  GE: DIMENSION_MATCH_CONDITIONS.GE,
  LT: DIMENSION_MATCH_CONDITIONS.LT,
  LE: DIMENSION_MATCH_CONDITIONS.LE,
  BETWEEN: DIMENSION_MATCH_CONDITIONS.BETWEEN,
} as const
export const dimensionNumericConditionOptions = [
  { value: DIMENSION_NUMERIC_CONDITIONS.EQ, label: '等しい' },
  { value: DIMENSION_NUMERIC_CONDITIONS.NOT_EQ, label: '異なる' },
  { value: DIMENSION_NUMERIC_CONDITIONS.GT, label: 'より大きい' },
  { value: DIMENSION_NUMERIC_CONDITIONS.GE, label: '以上' },
  { value: DIMENSION_NUMERIC_CONDITIONS.LT, label: 'より小さい' },
  { value: DIMENSION_NUMERIC_CONDITIONS.LE, label: '以下' },
  { value: DIMENSION_NUMERIC_CONDITIONS.BETWEEN, label: '間' },
]

export const DIMENSION_STRING_CONDITIONS = {
  FULL: DIMENSION_MATCH_CONDITIONS.FULL,
  PARTIAL: DIMENSION_MATCH_CONDITIONS.PARTIAL,
  HEAD: DIMENSION_MATCH_CONDITIONS.HEAD,
  TAIL: DIMENSION_MATCH_CONDITIONS.TAIL,
  REGEXP: DIMENSION_MATCH_CONDITIONS.REGEXP,
  NOT_FULL: DIMENSION_MATCH_CONDITIONS.NOT_FULL,
  NOT_PARTIAL: DIMENSION_MATCH_CONDITIONS.NOT_PARTIAL,
  NOT_HEAD: DIMENSION_MATCH_CONDITIONS.NOT_HEAD,
  NOT_TAIL: DIMENSION_MATCH_CONDITIONS.NOT_TAIL,
  NOT_REGEXP: DIMENSION_MATCH_CONDITIONS.NOT_REGEXP,
} as const
//
export const dimensionStringConditionOptions = [
  { value: DIMENSION_STRING_CONDITIONS.FULL, label: '完全一致' },
  { value: DIMENSION_STRING_CONDITIONS.PARTIAL, label: '含む' },
  { value: DIMENSION_STRING_CONDITIONS.HEAD, label: '前方一致' },
  { value: DIMENSION_STRING_CONDITIONS.TAIL, label: '後方一致' },
  { value: DIMENSION_STRING_CONDITIONS.REGEXP, label: '正規表現に一致' },
  { value: DIMENSION_STRING_CONDITIONS.NOT_FULL, label: '完全一致しない' },
  { value: DIMENSION_STRING_CONDITIONS.NOT_PARTIAL, label: '含まない' },
  { value: DIMENSION_STRING_CONDITIONS.NOT_HEAD, label: '前方一致しない' },
  { value: DIMENSION_STRING_CONDITIONS.NOT_TAIL, label: '後方一致しない' },
  { value: DIMENSION_STRING_CONDITIONS.NOT_REGEXP, label: '正規表現に一致しない' },
]

export const DIMENSION_STRING_WITH_EMPTY_CONDITIONS = {
  FULL: DIMENSION_MATCH_CONDITIONS.FULL,
  PARTIAL: DIMENSION_MATCH_CONDITIONS.PARTIAL,
  HEAD: DIMENSION_MATCH_CONDITIONS.HEAD,
  TAIL: DIMENSION_MATCH_CONDITIONS.TAIL,
  REGEXP: DIMENSION_MATCH_CONDITIONS.REGEXP,
  NOT_FULL: DIMENSION_MATCH_CONDITIONS.NOT_FULL,
  NOT_PARTIAL: DIMENSION_MATCH_CONDITIONS.NOT_PARTIAL,
  NOT_HEAD: DIMENSION_MATCH_CONDITIONS.NOT_HEAD,
  NOT_TAIL: DIMENSION_MATCH_CONDITIONS.NOT_TAIL,
  NOT_REGEXP: DIMENSION_MATCH_CONDITIONS.NOT_REGEXP,
  EMPTY: DIMENSION_MATCH_CONDITIONS.EMPTY,
  NOT_EMPTY: DIMENSION_MATCH_CONDITIONS.NOT_EMPTY,
} as const
export const dimensionStringWithEmptyConditionOptions = [
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.FULL, label: '完全一致' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.PARTIAL, label: '含む' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.HEAD, label: '前方一致' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.TAIL, label: '後方一致' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.REGEXP, label: '正規表現に一致' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.NOT_FULL, label: '完全一致しない' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.NOT_PARTIAL, label: '含まない' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.NOT_HEAD, label: '前方一致しない' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.NOT_TAIL, label: '後方一致しない' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.NOT_REGEXP, label: '正規表現に一致しない' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.EMPTY, label: '空を含む' },
  { value: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.NOT_EMPTY, label: '空を含まない' },
]

export const DIMENSION_CHOICE_CONDITIONS = {
  INCLUDE: DIMENSION_MATCH_CONDITIONS.INCLUDE,
  EXCLUDE: DIMENSION_MATCH_CONDITIONS.EXCLUDE,
} as const
export const dimensionChoiceConditionOptions = [
  { value: DIMENSION_CHOICE_CONDITIONS.INCLUDE, label: '含む' },
  { value: DIMENSION_CHOICE_CONDITIONS.EXCLUDE, label: '含まない' },
]

export const DIMENSION_BOOL_CONDITIONS = {
  EQ: DIMENSION_MATCH_CONDITIONS.BOOL_EQ,
  NOT_EQ: DIMENSION_MATCH_CONDITIONS.BOOL_NOT_EQ,
} as const
export const dimensionBoolConditionOptions = [
  { value: DIMENSION_BOOL_CONDITIONS.EQ, label: '一致する' },
  { value: DIMENSION_BOOL_CONDITIONS.NOT_EQ, label: '一致しない' },
]

export const DIMENSION_DATE_WITH_EMPTY_CONDITIONS = {
  DATE_BETWEEN: DIMENSION_MATCH_CONDITIONS.DATE_BETWEEN,
  DATE_EQ: DIMENSION_MATCH_CONDITIONS.DATE_EQ,
  DATE_LE: DIMENSION_MATCH_CONDITIONS.DATE_LE,
  DATE_GE: DIMENSION_MATCH_CONDITIONS.DATE_GE,
  EMPTY: DIMENSION_MATCH_CONDITIONS.EMPTY,
  NOT_EMPTY: DIMENSION_MATCH_CONDITIONS.NOT_EMPTY,
} as const
export const dimensionDateWithEmptyConditionOptions = [
  { value: DIMENSION_DATE_WITH_EMPTY_CONDITIONS.DATE_BETWEEN, label: '次の期間内' },
  { value: DIMENSION_DATE_WITH_EMPTY_CONDITIONS.DATE_EQ, label: '等しい' },
  { value: DIMENSION_DATE_WITH_EMPTY_CONDITIONS.DATE_LE, label: '日付以前' },
  { value: DIMENSION_DATE_WITH_EMPTY_CONDITIONS.DATE_GE, label: '日付以降' },
  { value: DIMENSION_DATE_WITH_EMPTY_CONDITIONS.EMPTY, label: '空を含む' },
  { value: DIMENSION_DATE_WITH_EMPTY_CONDITIONS.NOT_EMPTY, label: '空を含まない' },
]

// カスタムフィルターを追加する際に使用するURLパラメータ
export const ADDON_FILTERS_PARAM_NAME = 'addon_filters'
