import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { useEffect } from 'react'

import { ReportArea } from './ReportArea'
import { ReportPageLayout } from '../../components/layout/ReportPageLayout'
import { PAGE_TITLES } from '../../constants'
import { ProjectContext } from '../ProjectRoot'
import {
  useTrafficReportDimensionChoices,
  removeSearchParamsDimensionChoices,
} from '../../util/hooks/cookie/useTrafficReportDimensionChoices'

/**
 * トラフィックレポートページをレンダリングする
 */
export function TrafficReportPage(_: RouteComponentProps) {
  const { dimensionChoices } = useTrafficReportDimensionChoices()

  const {
    state: { projectId },
  } = React.useContext(ProjectContext)

  const mainAreaRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    // クリーンアップ関数でディメンションのURLパラメータを削除
    return () => {
      removeSearchParamsDimensionChoices()
    }
  }, [])

  return (
    <ReportPageLayout
      pageTitle={PAGE_TITLES.TRAFFIC_REPORT}
      mainAreaRef={mainAreaRef}
      reportAreaMaxWidth={`${getReportMaxWidth(dimensionChoices.length)}px`}
      paddingTop={8}
    >
      <ReportArea projectId={projectId} mainAreaRef={mainAreaRef} />
    </ReportPageLayout>
  )
}

const getReportMaxWidth = (dimensionChoicesLength: number) => {
  const base = 587
  const dimensionWidth = 200
  const adjustDimensionWidths =
    dimensionChoicesLength === 1
      ? dimensionWidth * 2 // 選択が1つの時は横幅を広く表示する
      : dimensionWidth * dimensionChoicesLength
  return base + adjustDimensionWidths
}
