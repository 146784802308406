/**
 * レポート画面で各アイテムのviewCount, goalCountなどをレポート内全ての値の最小を0、最大値を100としたスケールで計算
 * テーブルエリアのデータバー表示や、キャプチャエリアのグラデーション表示に使用
 *
 * 数値例: レポート内の全てのviewCountの最小値20, 最大値:120, 計算するviewCount:45の場合、
 * scaledPercent: ((45 - 20) / (120 - 20)) * 100 = 25% となる
 * @param count 計算する値
 * @param minCount レポート内の全ての値の最小値
 * @param maxCount レポート内の全ての値の最大値
 * @param isAdjustMinWidth 最小値のバー横幅が0pxにならないよう、最小値が0以上ならば横幅2%だけ出るように調整するかどうか
 * @param isReverse 最小値と最大値を入れ替えて計算するかどうか。Rankの場合は値が小さいほうがより100%に近くなるためtrueにする
 * @returns スケール後の値
 */
export const getScaledPercent = (
  count: number,
  minCount: number,
  maxCount: number,
  isAdjustMinWidth: boolean = true,
  isReverse: boolean = false,
): number => {
  if (count === 0) return 0

  // 値が同じ場合はデータバー100%表示。参考issue: https://github.com/uncovertruth/content-analytics/issues/2121#issuecomment-1849355816
  if (minCount === maxCount) return 100

  // 最小値のバー横幅が0pxにならないよう、最小値が0以上ならば横幅2%だけ出るように調整
  const adjustMinWidth = isAdjustMinWidth ? (maxCount - minCount) * 0.02 : 0
  const adjustMinCount = minCount - adjustMinWidth

  const percent = Math.round(((count - adjustMinCount) / (maxCount - adjustMinCount)) * 100)
  return isReverse ? 100 - percent : percent
}
