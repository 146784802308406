import { DimensionId, SortKey } from './types'
import { SCOPE_TYPE, ScopeType } from '../../useScopeType'
import { DIMENSION_IDS, SORT_KEYS } from './constants'

/**
 * ソートキーとスコープタイプを照合し、互換性があるかどうかを判断する
 *
 * @param {SortKey} sortKey - The sort key
 * @param {ScopeType} scopeType - The scope type
 * @return {boolean} - ソートキーとスコープタイプに互換性があればtrue、なければfalse
 */
export function matchSortKeyAndScopeType(sortKey: SortKey, scopeType: ScopeType): boolean {
  // ソートキーがセッション数・ユーザー数ではない場合、チェック不要
  if (sortKey !== SORT_KEYS.SESSION_COUNT && sortKey !== SORT_KEYS.USER_COUNT) return true

  return (
    (sortKey === SORT_KEYS.SESSION_COUNT && scopeType === SCOPE_TYPE.SESSION) ||
    (sortKey === SORT_KEYS.USER_COUNT && scopeType === SCOPE_TYPE.USER)
  )
}

/**
 * 指定されたソートキーが現在選択されているディメンションと互換性があるかをチェック
 *
 * ディメンションではないソートキーは有効として扱う
 *
 * @param {SortKey} sortKey - チェックするソートキー
 * @param {DimensionId[]} dimensionChoices - 現在選択されているディメンション
 * @returns {boolean} - ソートキーが有効な場合はtrue、無効な場合はfalse
 */
export function isSortKeyValidForDimensions(sortKey: SortKey, dimensionChoices: DimensionId[]): boolean {
  const dimensionId = castSortKeyToDimensionId(sortKey)
  // ディメンションに依存するソートキーの場合、対応するディメンションが選択されているかチェック
  return !dimensionId || dimensionChoices.includes(dimensionId)
}

function castSortKeyToDimensionId(value: SortKey): DimensionId | undefined {
  return Object.values(DIMENSION_IDS).find((id) => id === value)
}
