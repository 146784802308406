import * as React from 'react'
import styled from 'styled-components'
import { Crown } from '@styled-icons/fa-solid/Crown'
import { colors } from '../../styleConstants'

interface Props {
  readonly rank: number
  readonly isLarge: boolean
  readonly backgroundColor?: string
  readonly scale?: number
}

export function RankLabel({ rank, isLarge, backgroundColor, scale = 1 }: Props) {
  return (
    <RankBox isLarge={isLarge} backgroundColor={backgroundColor} scale={scale}>
      <StyledCrown size={16 * scale} color={`${colors.red}`} />
      <RankText isLarge={isLarge} scale={scale}>
        {rank}
      </RankText>
    </RankBox>
  )
}

const RankBox = styled.div<{ isLarge: boolean; backgroundColor?: string; scale: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isLarge, scale }) => `${scale * (isLarge ? 54 : 40)}px`};
  ${({ backgroundColor }) => backgroundColor && `background: ${backgroundColor}`};
`

const StyledCrown = styled(Crown)`
  width: ${({ size }) => `${size}px`};
  margin: 0 0 0 ${({ size }) => `${(size as number) * 0.25}px`};
`

const RankText = styled.div<{ isLarge: boolean; scale: number }>`
  color: ${colors.black2};
  font-size: ${({ isLarge, scale }) => `${scale * (isLarge ? 22 : 12)}px`};
  font-weight: bold;
  line-height: ${({ scale }) => `${scale * 20}px`};
  text-align: center;
  margin: 0 auto;
`
