import * as React from 'react'
import { layout } from '../../styleConstants'
import { convertRemToPx } from '../../util/Parse'
import { CAPTURE_AREA_DEFAULT_WIDTH, CAPTURE_AREA_MIN_WIDTH } from '../../components/ContentReport'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

interface State {
  readonly top: number
  readonly initialTop: number
  readonly gridRef: React.RefObject<HTMLDivElement>
}

// ヘッダ＋アクションメニュー＋マージンの高さ
const HeaderHeightAdjust = parseInt(layout.reportHeaderHeight) + convertRemToPx(1)

// TOPへボタンの位置
export const TopButtonAdjustPosition = {
  naviOpen: 270,
  naviClose: 80,
}

// キャプチャ画像のデバイス別の幅
export function calcImageWidth(splitWidth?: number) {
  return splitWidth && splitWidth > CAPTURE_AREA_MIN_WIDTH ? splitWidth : CAPTURE_AREA_DEFAULT_WIDTH
}

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // 初期位置
  setInitialTop = (top: number) => {
    this.setState({ ...this.state, initialTop: top, top: top })
  }

  scroll = (top: number) => {
    const height = HeaderHeightAdjust // ヘッダの高さとマージン分
    let t = top
    // 一定以上上へは表示されないようにする
    if (this.state.initialTop - top > height) {
      t = this.state.initialTop - top
    } else {
      t = height
    }
    this.setState({
      ...this.state,
      top: t,
    })
  }
}

const initialState: State = {
  top: HeaderHeightAdjust,
  initialTop: HeaderHeightAdjust,
  gridRef: React.createRef<HTMLDivElement>(),
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
