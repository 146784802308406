import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseCircle } from '@styled-icons/ionicons-sharp/CloseCircle'

import { colors } from '../../../styleConstants'
import { Input } from '../../common/Input'

interface Props {
  readonly value: string
  readonly onSearch: (value: string) => void
  readonly onClear: () => void
}

/**
 * 検索入力コンポーネントを表す
 *
 * ## Events
 * - 検索アイコンクリック:  入力値をonSearchコールバック関数に引数で渡して実行
 * - キーボードのEnterを押下: onSearchコールバック関数を実行
 * - クリアアイコンをクリック: onClearコールバック関数を実行
 *
 * @param {Object} props - The properties for the SearchText component.
 * @param {string} props.value - The current value of the search input.
 * @param {function} props.onSearch - 検索アイコン（キーボードのEnterも可）がクリックされたときに呼び出されるコールバック関数。
 * @param {function} props.onClear - クリアアイコンがクリックされたときに呼び出されるコールバック関数。
 * @returns {ReactElement|null} A React element representing the search input component.
 */
export const SearchText = ({ value, onSearch, onClear }: Props): ReactElement | null => {
  const [tempValue, setTempValue] = React.useState(value)

  React.useEffect(() => {
    if (value === tempValue) return
    setTempValue(value)
  }, [value])

  const isSearching = value !== ''

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => setTempValue(event.currentTarget.value)
  const handleSearch = () => onSearch(tempValue)

  const handleSearchByFormEvent = (event: React.FormEvent<HTMLFormElement>) => {
    // テキストボックスでキーボードEnterでもイベント実行できるようにする
    // HTMLフォームのページ再読み込みを防ぐため、preventDefaultを呼び出す
    event.preventDefault()
    handleSearch()
  }

  const handleClear = () => {
    setTempValue('')
    onClear()
  }

  return (
    <DesignedForm onSubmit={handleSearchByFormEvent}>
      <DesignedInput
        type="text"
        value={tempValue}
        onChange={handleInputChange}
        iconRight={
          isSearching ? (
            <IconArea>
              <ClearButton onClick={handleClear} />
              <SearchButton onClick={handleSearch} />
            </IconArea>
          ) : (
            <SearchButton onClick={handleSearch} />
          )
        }
      />
    </DesignedForm>
  )
}

const DesignedForm = styled.form`
  width: 100%;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 30px;
`

const IconArea = styled.div`
  display: flex;
`

const SearchButton = styled(Search).attrs({
  size: 18,
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const ClearButton = styled(CloseCircle).attrs({
  size: 18,
  color: `${colors.gray600}`,
})`
  cursor: pointer;
  margin-right: 0.3rem;
`
