import * as React from 'react'
import styled from 'styled-components'

import { colors, FONT_COLOR_MAIN } from '../../../styleConstants'
import { getDateYYYYMMDDhhmi } from '../../../util/Date'

interface Props {
  capturedAt?: string
}

export function CaptureGuideBox({ capturedAt }: Props) {
  const CaptureDateText = `表示中のキャプチャ取得日：${capturedAt ? getDateYYYYMMDDhhmi(capturedAt) : '-'}`

  return (
    <Container>
      <CaptureDateBox>
        <CaptureDateValue title={CaptureDateText}>{CaptureDateText}</CaptureDateValue>
      </CaptureDateBox>
      <CaptureGradationBox>
        <div>低</div>
        <CaptureGradationBar />
        <div>高</div>
      </CaptureGradationBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: ${colors.white};
  margin: 16px auto 0;
  padding: 8px 16px;
  width: 100%;
  max-width: 294px;
  gap: 12px;
`

const CaptureDateBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 0;
`

const CaptureDateValue = styled.span`
  text-align: center;
  width: 100%;
  min-width: 0;
  font-size: 10px;
  color: ${FONT_COLOR_MAIN};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CaptureGradationBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  font-size: 10px;
`

const CaptureGradationBar = styled.div`
  flex: 1;
  height: 16px;
  background: transparent linear-gradient(270deg, #ff4242 0%, #ffffff 50%, #3939ff 100%) 0% 0% no-repeat padding-box;
`
