import * as React from 'react'
import styled from 'styled-components'
import { TrafficReportItemData } from '../../../util/hooks/api/TrafficReport/types'
import { DIMENSION_IDS, DIMENSION_LABELS } from '../../../util/hooks/api/TrafficReport/constants'
import { useTrafficReportDimensionChoices } from '../../../util/hooks/cookie/useTrafficReportDimensionChoices'

const DIMENSION_VALUE_MAP = {
  [DIMENSION_IDS.CHANNEL]: (item: TrafficReportItemData) => item.channelName,
  [DIMENSION_IDS.SOURCE]: (item: TrafficReportItemData) => item.firstTrafficSource,
  [DIMENSION_IDS.MEDIUM]: (item: TrafficReportItemData) => item.firstTrafficMedium,
  [DIMENSION_IDS.CAMPAIGN]: (item: TrafficReportItemData) => item.firstTrafficCampaign,
} as const

interface Props {
  item: TrafficReportItemData
}

export const DimensionTooltip: React.FC<Props> = ({ item }) => {
  const { dimensionChoices } = useTrafficReportDimensionChoices()

  const dimensionTexts = dimensionChoices.map((dimensionId) => {
    const value = DIMENSION_VALUE_MAP[dimensionId](item)
    return `${DIMENSION_LABELS[dimensionId]}: ${value === '' ? '空を含む' : value}`
  })

  return (
    <HelpText>
      この条件に絞り込むフィルタを適用しページレポートに移動する
      <br />
      {dimensionTexts.join('　')}
    </HelpText>
  )
}

const HelpText = styled.div`
  text-align: left;
  font-weight: 400;
`
