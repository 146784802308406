import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../../styleConstants'
import { State, Actions, TAB_INDEX } from '../../../pages/ContentReport/state'
import { ArrowUp } from '@styled-icons/entypo/ArrowUp'
import { CaptureViewSelector } from '../../common/CaptureViewSelector'
import { CaptureGuideBox } from './CaptureGuideBox'
import { PageCaptureBox } from './PageCaptureBox'
import { Content, PageHistory, useContentReport } from '../../../util/hooks/api/useContentReport'
import { ContentGroup, useContentGroupReport } from '../../../util/hooks/api/useContentGroupReport'
import { ReportContext } from '../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { CAPTURE_AREA_MIN_WIDTH } from '../index'

function CaptureAreaContent({
  pageState,
  actions,
  imageWidth,
  history,
  contents,
}: {
  pageState: State
  actions: Actions
  imageWidth: number
  history: PageHistory
  contents: Content[] | ContentGroup[]
}) {
  const { selectedContentIds, contentScrollTo, isScrollTop, selectedTabIndex } = pageState

  const divRef = React.useRef<HTMLDivElement>(null)

  return (
    <>
      <CaptureGuideBox capturedAt={history.capturedAt} />
      <CaptureViewSelector />
      <Contents ref={divRef}>
        <PageCaptureBox
          history={history}
          selectedContentIds={selectedContentIds}
          contentScrollTo={contentScrollTo}
          isScrollTop={isScrollTop}
          onResetScroll={actions.onResetScroll}
          onResetScrollTop={actions.onResetScrollTop}
          onSelect={(props) => {
            actions.onContentCaptureSelect(props, contents)
          }}
          imageWidth={imageWidth}
          selectedTabIndex={selectedTabIndex}
        />

        {/* TOPに戻るボタン ナビ状態で表示位置変更*/}
        <TopButton onClick={actions.onScrollToTop}>
          <div>TOPへ</div>
          <ArrowUp color={`${colors.white}`} size={20} style={{ margin: 0, padding: 0 }} />
        </TopButton>
      </Contents>
    </>
  )
}

function AllContentsCaptureArea(props: CaptureAreaProps) {
  const {
    allContentsState: { sortState },
  } = props.pageState

  const {
    state: { calenderState },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)

  const { data, isSuccess } = useContentReport({
    projectId: props.projectId,
    reportId: props.reportId,
    calenderState,
    customFilterState,
    sortState,
  })
  if (!isSuccess) return null
  const history = data.pageHistories.find((item) => item.id === props.allContentsDisplayHistoryId)
  if (!history) return null

  return <CaptureAreaContent {...props} history={history} contents={data.contents} />
}

function ContentGroupCaptureArea(props: CaptureAreaProps) {
  const {
    contentGroupState: { sortState },
  } = props.pageState

  const {
    state: { calenderState },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)

  const { data, isSuccess } = useContentGroupReport({
    projectId: props.projectId,
    reportId: props.reportId,
    calenderState,
    customFilterState,
    sortState,
  })
  if (!isSuccess) return null
  const history = data.pageHistories.find((item) => item.id === props.contentsGroupDisplayHistoryId)
  if (!history) return null

  return <CaptureAreaContent {...props} history={history} contents={data.contentGroups} />
}

interface CaptureAreaProps {
  pageState: State
  actions: Actions
  imageWidth: number
  readonly allContentsDisplayHistoryId: number
  readonly contentsGroupDisplayHistoryId: number
  readonly projectId: string
  readonly reportId: string
}

export function CaptureArea(props: CaptureAreaProps) {
  return (
    <GridBoxWrapper>
      <GridBox>
        {props.pageState.selectedTabIndex === TAB_INDEX.ALL_CONTENTS && <AllContentsCaptureArea {...props} />}
        {props.pageState.selectedTabIndex === TAB_INDEX.CONTENT_GROUP && <ContentGroupCaptureArea {...props} />}
      </GridBox>
    </GridBoxWrapper>
  )
}

const GridBoxWrapper = styled.div`
  overflow-x: auto;
`

const GridBox = styled.div`
  min-width: ${CAPTURE_AREA_MIN_WIDTH}px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 0;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Contents = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 235px);
`

const TopButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 32px;
  right: 32px;
  padding: 0.3rem;
  font-size: 0.6rem;
  color: ${colors.white};
  background-color: ${colors.blue};
  z-index: ${layout.popupZIndex};
  cursor: pointer;
`
