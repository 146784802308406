import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './Store'

export interface State {
  readonly navigationState: {
    clicked: boolean
    hovered: boolean
    opened: boolean
    isIconClickedOnce: boolean
  }
  readonly accordionState: {
    readonly reportOpen: boolean
    readonly captureOpen: boolean
    readonly projectSettingOpen: boolean
  }
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    navigationState: { clicked: true, hovered: false, opened: true, isIconClickedOnce: false },
    accordionState: {
      reportOpen: true,
      captureOpen: false,
      projectSettingOpen: false,
    },
  },
  reducers: {
    click: (state: State) => {
      return {
        ...state,
        navigationState: {
          ...state.navigationState,
          isIconClickedOnce: true,
          clicked: !state.navigationState.clicked,
          opened: !state.navigationState.clicked || state.navigationState.hovered,
        },
      }
    },
    hover: (state: State) => ({
      ...state,
      navigationState: {
        ...state.navigationState,
        hovered: !state.navigationState.hovered,
        opened: state.navigationState.clicked || !state.navigationState.hovered,
      },
    }),
    close: (state: State) => ({
      ...state,
      navigationState: {
        ...state.navigationState,
        clicked: false,
        hovered: false,
        opened: false,
      },
    }),
    // ナビの項目の開閉
    onNaviAccordionChange: (
      state: State,
      actions: PayloadAction<{ accordionName: keyof State['accordionState']; open: boolean }>,
    ) => {
      const { accordionName, open } = actions.payload
      return { ...state, accordionState: { ...state.accordionState, [accordionName]: open } }
    },
  },
})

export const { click, hover, close, onNaviAccordionChange } = navigationSlice.actions
export const navigationState = (state: RootState) => state.navigation.navigationState
export const accordionState = (state: RootState) => state.navigation.accordionState

export default navigationSlice.reducer
