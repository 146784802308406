import * as React from 'react'
import styled from 'styled-components'
import { Checkbox } from '../../../components/common/Checkbox'
import { colors } from '../../../styleConstants'
import { DIMENSION_IDS, DIMENSION_LABELS } from '../../../util/hooks/api/TrafficReport/constants'
import { DimensionId } from '../../../util/hooks/api/TrafficReport/types'
import {
  setSearchParamsDimensionChoices,
  useTrafficReportDimensionChoices,
} from '../../../util/hooks/cookie/useTrafficReportDimensionChoices'
import { InteractiveTooltip } from '../../../components/Tooltip'
import { getDimensionBackgroundColorByOrder, getDimensionBorderColorByOrder } from '../utils'
import { HeaderCellHelp } from '../../../components/ContentReport/cells/HeaderCellHelp'
import { HELP_TEXTS } from '../../../constants'

interface HelpContent {
  text: string
  width: number
}

const DIMENSION_HELP_CONTENTS: Record<DimensionId, HelpContent> = {
  [DIMENSION_IDS.CHANNEL]: {
    text: HELP_TEXTS.DIMENSION_CHANNEL_NAME,
    width: 400,
  },
  [DIMENSION_IDS.SOURCE]: {
    text: HELP_TEXTS.DIMENSION_FIRST_TRAFFIC_SOURCE,
    width: 300,
  },
  [DIMENSION_IDS.MEDIUM]: {
    text: HELP_TEXTS.DIMENSION_FIRST_TRAFFIC_MEDIUM,
    width: 300,
  },
  [DIMENSION_IDS.CAMPAIGN]: {
    text: HELP_TEXTS.DIMENSION_FIRST_TRAFFIC_CAMPAIGN,
    width: 300,
  },
} as const

/**
 * トラフィックレポートのディメンション選択チェックボックス
 *
 * @remarks
 * - チェックボックスをクリックすることでディメンション（チャネル、参照元、メディア、キャンペーン）の表示・非表示を切り替えることができます
 * - 選択状態はCookieに保存され、ページをリロードしても維持されます
 * - 選択した順番は配列として保存されます。
 *
 * @returns {JSX.Element} ディメンション選択用のチェックボックスグループ
 */
export const DimensionCheckboxes = () => {
  const { dimensionChoices, setDimensionChoices } = useTrafficReportDimensionChoices()

  const handleClickChoice = (clickedDimension: DimensionId) => {
    // すでに選択されている場合は削除する
    if (dimensionChoices.includes(clickedDimension)) {
      // 1つしか選択されていない場合は削除できないようにする
      if (dimensionChoices.length === 1) return
      const removedChoices = dimensionChoices.filter((dimensionId) => dimensionId !== clickedDimension)
      setDimensionChoices(removedChoices)
      setSearchParamsDimensionChoices(removedChoices)
      return
    }
    // 新しい選択を追加
    const addedChoices = [...dimensionChoices, clickedDimension]
    setDimensionChoices(addedChoices)
    setSearchParamsDimensionChoices(addedChoices)
  }

  return (
    <Container>
      {Object.values(DIMENSION_IDS).map((dimensionId) => (
        <InteractiveTooltip
          key={dimensionId}
          content={'最低1つは選択する必要があります'}
          disabled={!dimensionChoices.includes(dimensionId) || dimensionChoices.length > 1}
          offset={[15, 5]}
        >
          <CheckboxWrapper
            onClick={() => handleClickChoice(dimensionId)}
            indexOf={dimensionChoices.indexOf(dimensionId)}
          >
            <StyledCheckbox checked={dimensionChoices.includes(dimensionId)} />
            <CheckboxLabel checked={dimensionChoices.includes(dimensionId)}>
              {DIMENSION_LABELS[dimensionId]}
            </CheckboxLabel>
            <HeaderCellHelp width={DIMENSION_HELP_CONTENTS[dimensionId].width}>
              {DIMENSION_HELP_CONTENTS[dimensionId].text}
            </HeaderCellHelp>
          </CheckboxWrapper>
        </InteractiveTooltip>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 8px;
`

interface CheckboxWrapperProps {
  indexOf: number
}

const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ indexOf }) => getDimensionBorderColorByOrder(indexOf)};
  border-radius: 4px;
  padding: 5px 11px;
  background-color: ${({ indexOf }) => getDimensionBackgroundColorByOrder(indexOf)};
  min-width: 116px;
  gap: 7px;
`

const StyledCheckbox = styled(Checkbox)`
  width: 13px;
  height: 13px;
  margin-right: 7px;
`

interface CheckboxLabelProps {
  checked?: boolean
}

const CheckboxLabel = styled.div<CheckboxLabelProps>`
  font-size: 12px;
  font-weight: ${({ checked }) => (checked ? 'bold' : 'normal')};
  color: ${({ checked }) => (checked ? colors.black : colors.gray750)};
`
