import { toMs } from '../../../util/Parse'
import { ContentLocation, ContentLocationWithScaled } from '../../../util/hooks/api/useContentReport'
import { getScaledPercent } from '../../../util/getScaledPercent'
import { CAPTURE_VIEW, CaptureView } from '../../../util/hooks/useCaptureView'
import { colors } from '../../../styleConstants'

type RankData = {
  min: number
  max: number
}
const getRankMinMax = (ranks: number[]): RankData => ({
  min: Math.min(...ranks),
  max: Math.max(...ranks),
})
/**
 * コンテンツロケーションにランクのスケールドパーセントを追加した配列を返す
 * @param {ContentLocation[]} contentLocations - コンテンツロケーションの配列
 * @returns {ContentLocationWithScaled[]} ランクのスケールドパーセントを追加したコンテンツロケーションの配列
 */
export const getContentLocationsWithScaled = (contentLocations: ContentLocation[]): ContentLocationWithScaled[] => {
  const viewCountRanks = contentLocations.map((location) => location.viewCountRank)
  const viewRateRanks = contentLocations.map((location) => location.viewRateRank)
  const viewSecondRanks = contentLocations.map((location) => location.viewSecondRank)
  const goalCountRanks = contentLocations.map((location) => location.goalCountRank)
  const goalRateRanks = contentLocations.map((location) => location.goalRateRank)
  const clickCountRanks = contentLocations.map((location) => location.clickCountRank)
  const clickRateRanks = contentLocations.map((location) => location.clickRateRank)

  const { viewCount, viewRate, viewSecond, goalCount, goalRate, clickCount, clickRate } = {
    viewCount: getRankMinMax(viewCountRanks),
    viewRate: getRankMinMax(viewRateRanks),
    viewSecond: getRankMinMax(viewSecondRanks),
    goalCount: getRankMinMax(goalCountRanks),
    goalRate: getRankMinMax(goalRateRanks),
    clickCount: getRankMinMax(clickCountRanks),
    clickRate: getRankMinMax(clickRateRanks),
  }

  return contentLocations.map((location) => ({
    ...location,
    viewCountRankScaledPercent: location.viewCountRank
      ? getScaledPercent(location.viewCountRank, viewCount.min, viewCount.max, false, true)
      : 0,
    viewRateRankScaledPercent: location.viewRateRank
      ? getScaledPercent(location.viewRateRank, viewRate.min, viewRate.max, false, true)
      : 0,
    viewSecondRankScaledPercent: location.viewSecondRank
      ? getScaledPercent(location.viewSecondRank, viewSecond.min, viewSecond.max, false, true)
      : 0,
    goalCountRankScaledPercent: location.goalCountRank
      ? getScaledPercent(location.goalCountRank, goalCount.min, goalCount.max, false, true)
      : 0,
    goalRateRankScaledPercent: location.goalRateRank
      ? getScaledPercent(location.goalRateRank, goalRate.min, goalRate.max, false, true)
      : 0,
    clickCountRankScaledPercent: location.clickCountRank
      ? getScaledPercent(location.clickCountRank, clickCount.min, clickCount.max, false, true)
      : 0,
    clickRateRankScaledPercent: location.clickRateRank
      ? getScaledPercent(location.clickRateRank, clickRate.min, clickRate.max, false, true)
      : 0,
  }))
}

/**
 * パーセント値を指定のフォーマットで文字列に変換する
 * - 整数2桁以上: 小数点以下を四捨五入して整数2桁にする
 * - 整数1桁以下: 指定された小数点桁数で四捨五入して表示
 * @param value - パーセント値
 * @param decimalPlaces - 小数点以下の桁数
 * @returns {string} フォーマットされたパーセント文字列
 * @example
 * formatPercentValue(0, 1) // => '0%'
 * formatPercentValue(3.987, 1) // => '4.0%'
 * formatPercentValue(3.987, 2) // => '3.99%'
 * formatPercentValue(61.98, 1) // => '62%'
 * formatPercentValue(61.98, 2) // => '62%'
 */
const formatPercentValue = (value: number, decimalPlaces: number): string => {
  if (value === 0) return '0%'
  return value >= 10 ? `${Math.round(value)}%` : `${value.toFixed(decimalPlaces)}%`
}

type MetricItem = {
  readonly rank: number // ランク
  readonly rankScaledPercent: number // ランクを0から100でスケール済みのパーセント値
  readonly formattedValue: string // 「0.0%, 0.00%, 00:00」などにフォーマット済みの値
}
/**
 * プルダウンで選択中の指標データの詳細を取得する
 * @param location - コンテンツロケーション情報
 * @param captureView - 選択された指標の種類
 * @returns {MetricItem} 指標データ（ランク、スケール済みパーセント値、フォーマット済み値）
 */
export const getMetricItem = (location: ContentLocationWithScaled, captureView: CaptureView): MetricItem => {
  switch (captureView) {
    case CAPTURE_VIEW.VIEW_COUNT:
      return {
        rank: location.viewCountRank,
        rankScaledPercent: location.viewCountRankScaledPercent,
        formattedValue: Math.round(location.viewCount).toLocaleString(),
      }
    case CAPTURE_VIEW.VIEW_RATE:
      return {
        rank: location.viewRateRank,
        rankScaledPercent: location.viewRateRankScaledPercent,
        formattedValue: formatPercentValue(location.viewRate, 1),
      }
    case CAPTURE_VIEW.VIEW_SECOND:
      return {
        rank: location.viewSecondRank,
        rankScaledPercent: location.viewSecondRankScaledPercent,
        formattedValue: toMs(location.viewSecond),
      }
    case CAPTURE_VIEW.GOAL_COUNT:
      return {
        rank: location.goalCountRank,
        rankScaledPercent: location.goalCountRankScaledPercent,
        formattedValue: Math.round(location.goalCount).toLocaleString(),
      }
    case CAPTURE_VIEW.GOAL_RATE:
      return {
        rank: location.goalRateRank,
        rankScaledPercent: location.goalRateRankScaledPercent,
        formattedValue: formatPercentValue(location.goalRate, 2),
      }
    case CAPTURE_VIEW.CLICK_COUNT:
      return {
        rank: location.clickCountRank,
        rankScaledPercent: location.clickCountRankScaledPercent,
        formattedValue: Math.round(location.clickCount).toLocaleString(),
      }
    case CAPTURE_VIEW.CLICK_RATE:
      return {
        rank: location.clickRateRank,
        rankScaledPercent: location.clickRateRankScaledPercent,
        formattedValue: formatPercentValue(location.clickRate, 1),
      }
    default:
      return {
        rank: 0,
        rankScaledPercent: 0,
        formattedValue: '0',
      }
  }
}

/**
 * スケール済みパーセント値を元にグラデーションのRGB値を計算する
 * @param scaledPercent - 0から100でスケール済みのパーセント値
 * @returns {string} RGB値（例: "255, 255, 255"）
 */
export const getGradationRGB = (scaledPercent: number): string => {
  if (scaledPercent < 50) {
    // 0-50%: 青から白へのグラデーション
    const ratio = scaledPercent / 50
    const blue = 255
    const redGreen = Math.round(255 * ratio)
    return `${redGreen}, ${redGreen}, ${blue}`
  } else {
    // 50-100%: 白から赤へのグラデーション
    const ratio = (scaledPercent - 50) / 50
    const red = 255
    const greenBlue = Math.round(255 * (1 - ratio))
    return `${red}, ${greenBlue}, ${greenBlue}`
  }
}

export const getGroupLabelBackgroundColor = (scaledPercent: number): string => {
  return scaledPercent < 50 ? colors.contentBlue.blue9 : colors.red
}
