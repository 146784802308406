import { DimensionId } from './types'

export const DIMENSION_IDS = {
  CHANNEL: 'channel_name',
  SOURCE: 'first_traffic_source',
  MEDIUM: 'first_traffic_medium',
  CAMPAIGN: 'first_traffic_campaign',
} as const

export const DIMENSION_LABELS: Record<DimensionId, string> = {
  [DIMENSION_IDS.CHANNEL]: 'チャネル',
  [DIMENSION_IDS.SOURCE]: '参照元',
  [DIMENSION_IDS.MEDIUM]: 'メディア',
  [DIMENSION_IDS.CAMPAIGN]: 'キャンペーン',
} as const

export const SORT_KEYS = {
  CHANNEL_NAME: DIMENSION_IDS.CHANNEL,
  FIRST_TRAFFIC_SOURCE: DIMENSION_IDS.SOURCE,
  FIRST_TRAFFIC_MEDIUM: DIMENSION_IDS.MEDIUM,
  FIRST_TRAFFIC_CAMPAIGN: DIMENSION_IDS.CAMPAIGN,
  SESSION_COUNT: 'session_count',
  USER_COUNT: 'user_count',
  BOUNCE_RATE: 'bounce_rate',
  GOAL_COUNT: 'goal_count',
  GOAL_RATE: 'goal_rate',
} as const
