import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { HelpTooltip } from '../../Tooltip/IconTooltip'

interface Props {
  readonly children: React.ReactNode
  readonly width?: number
  readonly left?: number
}

export function HeaderCellHelp({ children, width, left }: Props) {
  return (
    <HelpTooltip width={width || 150} left={left} iconSize="16" borderRadius="20px" iconColor={`${colors.lightBlue}`}>
      <HelpText>{children}</HelpText>
    </HelpTooltip>
  )
}

const HelpText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: normal;
`
