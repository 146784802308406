import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps, Redirect, useLocation } from '@gatsbyjs/reach-router'
import { Authority } from '../util/Authority'
import { UrlParams } from '../util/UrlParams'
import { GlobalContext } from '../GlobalState'
import { DeviceType, setSearchParamsDeviceType, useDeviceType } from '../util/hooks/useDeviceType'
import { TAB_INDEX, usePageState } from './ContentReport/state'
import { ContentRegisterModal } from '../components/ContentReport/ContentRegisterModal'
import { usePageState as usePageResizeState } from './ContentReport/resizeState'
import { PageLayout } from '../components/layout/PageLayout'
import { Loading } from '../components/common/Loading'
import { PageHeader } from '../components/common/PageHeader'
import { CustomFilterUseContext } from '../components/filter/CustomFilter'
import { Toolbar } from '../components/common/Toolbar'
import { ReportArea } from '../components/ContentReport/ReportArea'
import { useContentReport } from '../util/hooks/api/useContentReport'
import { useContentGroupReport } from '../util/hooks/api/useContentGroupReport'
import { useContentReportCsvDownload } from '../util/hooks/api/useContentReportCsvDownload'
import { usePageCaptureImageDownload } from '../util/hooks/api/usePageCaptureImageDownload'
import { navigate } from '@gatsbyjs/reach-router'
import { ReportContext } from '../contexts/ReportProvider'
import { CustomFilterContext } from '../contexts/CustomFilterContext'
import { PAGE_TITLES } from '../constants'
import { BreadcrumbsItemType } from '../components/breadcrumbs/Breadcrumbs'

interface ContentReportProps extends RouteComponentProps {
  projectId?: string
  pageReportId?: string
}

export function ContentReport(props: ContentReportProps) {
  const projectId = props.projectId || ''
  const reportId = props.pageReportId || ''

  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { calenderState },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState, openedFilter },
    actions: customFilterContextActions,
  } = React.useContext(CustomFilterContext)

  const { state: pageState, actions } = usePageState()

  const { deviceType, setDeviceType } = useDeviceType()

  const { initialized, reload, availablePage, allContentsState, contentGroupState, selectedTabIndex } = pageState
  const { state, actions: resizeActions } = usePageResizeState()
  const { gridRef } = state
  const isAllContentTabView = selectedTabIndex === TAB_INDEX.ALL_CONTENTS

  // URLパラメータ
  const location = useLocation()

  const handleGoalChange = () => {
    actions.onGoalChange()
  }

  const handleDeviceChange = (value: DeviceType) => {
    setSearchParamsDeviceType(value)
    setDeviceType(value)
    actions.changeDevice()
  }

  React.useEffect(() => {
    // ページレポート用のパラメータを削除
    const url = UrlParams.deleteParams(new URL(location.href), 'disp')
    history.replaceState(null, '', url)

    actions.initialize(projectId, reportId)
  }, [])

  React.useEffect(() => {
    if (reload) {
      actions.refetch()
    }
  }, [reload])

  React.useEffect(() => {
    if (!initialized) return
    if (gridRef && gridRef.current) {
      resizeActions.setInitialTop(gridRef.current.getBoundingClientRect().top)
    }
  }, [gridRef.current, gridRef.current && gridRef.current.offsetTop])

  const baseUrl = `/projects/${projectId}/`

  // データがないページの場合、ページレポートにリダイレクト
  if (initialized && !availablePage) {
    return <Redirect to={`/projects/${projectId}/report/page/`} noThrow />
  }

  const { search } = window.location

  const breadcrumbsItems: BreadcrumbsItemType[] = [
    {
      text: PAGE_TITLES.PAGE_REPORT,
      link: `${baseUrl}report/page${search}`,
    },
  ]

  const [allContentsDisplayHistoryId, setAllContentsDisplayHistoryId] = React.useState(0)
  const [contentsGroupDisplayHistoryId, setContentsGroupDisplayHistoryId] = React.useState(0)

  const {
    data: contentReportData,
    isError: isContentReportError,
    isLoading: isContentReportLoading,
  } = useContentReport({
    projectId,
    reportId,
    calenderState,
    customFilterState,
    sortState: allContentsState.sortState,
    enabled: isAllContentTabView,
  })

  const {
    data: contentGroupReportData,
    isError: isContentGroupReportError,
    isLoading: isContentGroupReportLoading,
  } = useContentGroupReport({
    projectId,
    reportId,
    calenderState,
    customFilterState,
    sortState: contentGroupState.sortState,
    enabled: !isAllContentTabView,
  })

  const {
    mutate: csvDownloadMutate,
    isLoading: isCsvDownLoading,
    isError: isCsvDownloadError,
  } = useContentReportCsvDownload({
    projectId,
    pageReportId: Number(reportId),
  })

  const {
    mutate: imageDownloadMutate,
    isLoading: isImageDownLoading,
    isError: isImageDownloadError,
  } = usePageCaptureImageDownload({ projectId })

  React.useEffect(() => {
    if (contentReportData) {
      actions.onSuccessFetchAllContents(contentReportData, customFilterState)
      setAllContentsDisplayHistoryId(contentReportData.displayPageHistoryId)
    }
  }, [contentReportData])
  React.useEffect(() => {
    if (isContentReportError) {
      actions.onErrorFetchAllContents()
    }
  }, [isContentReportError])
  React.useEffect(() => {
    if (contentGroupReportData) {
      actions.onSuccessFetchContentGroup(contentGroupReportData, customFilterState)
      setContentsGroupDisplayHistoryId(contentGroupReportData.initPageHistoryId)
    }
  }, [contentGroupReportData])
  React.useEffect(() => {
    if (isContentGroupReportError) {
      actions.onErrorFetchContentGroup()
    }
  }, [isContentGroupReportError])
  React.useEffect(() => {
    if (isCsvDownloadError) {
      actions.setErrorMessage('CSVダウンロードに失敗しました。')
      return
    }
    actions.setErrorMessage('')
  }, [isCsvDownloadError])
  React.useEffect(() => {
    if (isImageDownloadError) {
      actions.setErrorMessage('キャプチャダウンロードに失敗しました。')
      return
    }
    actions.setErrorMessage('')
  }, [isImageDownloadError])

  const urlTitle = (isAllContentTabView ? contentReportData?.urlTitle : contentGroupReportData?.urlTitle) || ''

  const title = `${PAGE_TITLES.CONTENT_REPORT} | ${AccountInfo.projectName} | ${AccountInfo.teamName}チーム`

  const urlId = isAllContentTabView ? contentReportData?.urlId : contentGroupReportData?.urlId

  const isCaptureFailed = isAllContentTabView
    ? contentReportData?.isCaptureFailed
    : contentGroupReportData?.isCaptureFailed

  const pageHistories = isAllContentTabView ? contentReportData?.pageHistories : contentGroupReportData?.pageHistories

  const isLoading =
    !initialized ||
    (isAllContentTabView && isContentReportLoading) ||
    (!isAllContentTabView && isContentGroupReportLoading)

  const pageCaptureLink = isCaptureFailed
    ? `/projects/${projectId}/report/content/${reportId}/capture/${urlId}?tab=history`
    : `/projects/${projectId}/report/content/${reportId}/capture/${urlId}`
  return (
    <div>
      <PageHeader title={title} description={title} />
      {isLoading && <Loading />}

      {/* ファイル系ダウンロード中は表などを表示したままとしたいので、変数`isLoading`とは別で管理 */}
      {(isCsvDownLoading || isImageDownLoading) && <Loading />}

      <ContentRegisterModal
        state={pageState}
        actions={actions}
        projectId={projectId}
        reportId={reportId}
        canAddManagedContent={Authority.canAddManagedContent(AccountInfo.permissions, projectId)}
        canChangeManagedContent={Authority.canChangeManagedContent(AccountInfo.permissions, projectId)}
      />
      {openedFilter && <CustomFilterUseContext />}

      <PageLayout
        headerTitle={PAGE_TITLES.CONTENT_REPORT}
        optionHidden={false}
        baseUrl={baseUrl}
        url={isAllContentTabView ? contentReportData?.url : contentGroupReportData?.url}
        urlTitle={urlTitle}
        isReportPage={true}
        breadcrumbsItems={breadcrumbsItems}
        onGoalChange={handleGoalChange}
        toolbar={
          <Toolbar
            canView={Authority.canViewReport(AccountInfo.permissions, projectId)}
            selectedDevice={deviceType}
            onFilterClick={customFilterContextActions.open}
            onChangeDevice={handleDeviceChange}
            toolMenuItems={[
              {
                name: 'ページキャプチャ',
                onClick: (e) => {
                  if (e) {
                    e.preventDefault()
                    navigate(pageCaptureLink)
                  }
                },
                href: pageCaptureLink,
                isError: isCaptureFailed,
              },
              {
                name: 'キャプチャダウンロード',
                onClick: Authority.canDownloadReport(AccountInfo.permissions, projectId)
                  ? () => {
                      imageDownloadMutate({
                        pageHistories,
                        displayPageHistoryId: isAllContentTabView
                          ? allContentsDisplayHistoryId
                          : contentsGroupDisplayHistoryId,
                      })
                    }
                  : () => alert('現在のプランではご利用いただけません'),
              },
              {
                name: 'CSVダウンロード',
                onClick: () => {
                  Authority.canDownloadReport(AccountInfo.permissions, projectId)
                    ? csvDownloadMutate({
                        calenderState,
                        customFilterState,
                        tabIndex: selectedTabIndex,
                      })
                    : alert('現在のプランではご利用いただけません')
                },
              },
            ]}
          />
        }
      >
        {!Authority.canViewReport(AccountInfo.permissions, projectId) && (
          <div style={{ margin: '1rem 0 0 1rem' }}>閲覧権限がありません。</div>
        )}
        {!isLoading && Authority.canViewReport(AccountInfo.permissions, projectId) && (
          <>
            <Container>
              <ReportArea
                actions={actions}
                gridRef={gridRef}
                pageState={pageState}
                pageId={isAllContentTabView ? contentReportData?.pageId : contentGroupReportData?.pageId}
                allContentsDisplayHistoryId={allContentsDisplayHistoryId}
                contentsGroupDisplayHistoryId={contentsGroupDisplayHistoryId}
                setAllContentsDisplayHistoryId={(newValue: number) => {
                  setAllContentsDisplayHistoryId(newValue)
                }}
                setContentsGroupDisplayHistoryId={(newValue: number) => {
                  setContentsGroupDisplayHistoryId(newValue)
                }}
                projectId={projectId}
                reportId={reportId}
              />
            </Container>
          </>
        )}
      </PageLayout>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
