import * as React from 'react'
import Tippy from '@tippyjs/react/headless'
import styled from 'styled-components'
import { colors, layout } from '../../styleConstants'
import { QuestionCircle } from '@styled-icons/bootstrap/QuestionCircle'
import { Information } from '@styled-icons/remix-line/Information'
import { MessageRoundedError } from '@styled-icons/boxicons-solid/MessageRoundedError'

interface CommonIconTooltipProps {
  readonly width?: number
  readonly height?: number
  readonly top?: number
  readonly left?: number
  readonly children?: React.ReactNode
}

interface BaseIconTooltipProps extends CommonIconTooltipProps {
  readonly iconNode: React.ReactNode
}

interface HelpTooltipProps extends CommonIconTooltipProps {
  readonly iconSize: string
  readonly iconColor: string
  readonly borderRadius: string
}

interface InformationTooltipProps extends CommonIconTooltipProps {
  readonly iconSize: string
  readonly iconColor: string
}

function BaseIconTooltip({
  width,
  height,
  top,
  left,
  children,
  iconNode,
}: BaseIconTooltipProps & { borderRadius?: string }) {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // ツールチップ内をクリックしても親コンポーネントに伝播させない
    event.stopPropagation()
  }
  return (
    <Container>
      <Tippy
        interactive={true}
        // delayは、次のアイコンにカーソル移動する時には消えそうな時間を設定する
        delay={[0, 100]}
        render={() => (
          <Tooltip onClick={handleClick} width={width} height={height} top={top} left={left}>
            {children}
          </Tooltip>
        )}
      >
        {/* MEMO: Tippy は `＜＞` フラグメントには反応せず `＜div＞` である必要があった */}
        <div style={{ display: 'flex' }}>{iconNode}</div>
      </Tippy>
    </Container>
  )
}

export function HelpTooltip({ borderRadius, iconSize, iconColor, ...otherProps }: HelpTooltipProps) {
  return (
    <BaseIconTooltip
      {...otherProps}
      iconNode={
        <IconCircleWrapper borderRadius={borderRadius}>
          <QuestionCircle size={iconSize} color={iconColor} />
        </IconCircleWrapper>
      }
    />
  )
}

export function InformationTooltip({ iconSize, iconColor, ...otherProps }: InformationTooltipProps) {
  return (
    <BaseIconTooltip
      {...otherProps}
      iconNode={
        <IconWrapper>
          <Information size={iconSize} color={iconColor} />
        </IconWrapper>
      }
    />
  )
}

export function ErrorIconTooltip(props: CommonIconTooltipProps) {
  return <BaseIconTooltip {...props} iconNode={<StyledMessageRoundedError size={16} />} />
}

const Container = styled.div`
  margin-left: 0.2rem;
`

const Tooltip = styled.div<{ width?: number; height?: number; top?: number; left?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  width: ${({ width }) => width || 170}px;
  height: ${({ height }) => (height !== undefined ? `${height}px` : 'auto')};
  top: ${({ top }) => top || 50}px;
  left: ${({ left }) => left || -85}px;
  padding: 0.5rem;
  font-size: 0.7rem;
  color: ${colors.white};
  background-color: rgba(20, 40, 48, 0.8);
  box-shadow: 0 0 4px ${colors.gray400};
  z-index: ${layout.popupZIndex};
  white-space: pre-line;
`

const IconCircleWrapper = styled.div<{ borderRadius?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  border-radius: ${(props) => props.borderRadius};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledMessageRoundedError = styled(MessageRoundedError)`
  color: ${colors.error};
`
